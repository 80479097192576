import React, { useEffect, useState } from "react";
import MoslemeTable from "components/Table";
import Badge from "components/badge";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";

import {
  handleGetAllManifest,
  handleUploadAllManifest,
} from "stores/actions/paket/manifest";
import { handleError, handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import { useParams } from "react-router-dom";
import Avatar from "components/avatar";
import PerlengkapanManifest from "./PerlengkapanManifest";
import { formattorbase64, toCapital } from "utils/helpers";
import Popup from "components/popup";
import ManifestForm from "./ManifestForm";
import Tab from "components/tab";
import Pembimbing from "./Pembimbing";
import Inputs from "components/form/inputs";

import IconSearch from "assets/icons/search-normal.svg";
import { ReactComponent as Receive } from "assets/icons/receive-square.svg";
import useDebounce from "utils/helpers/useDebounce";

import { exportManifestJamaah, exportManifestPembimbing } from "utils/api/paket/manifest";
import Spinner from "components/spinner";

const PaketLaporanManifest = () => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const [isUpdateManifest, setIsUpdateManifest] = useState(false);
  const [updateManifestKey, setUpdateManifestKey] = useState('pas_photo_4x6')
  const [updateManifestTitle, setUpdateManifestTitle] = useState("Pas Photo 4x6")
  const [dataManifestSelected, setDataManifestSelected] = useState({})
  const [activeTab, setActiveTab] = useState("1")
  const [exporting, setExporting] = useState(false)
  
  const auth = useSelector(({auth}) => auth)
  const permissions = auth?.user?.permissions;

  const [keyword, setKeyword] = useState("");
  const searchDebounce = useDebounce(keyword, 1000); 

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [listDataManifest, setListDataManifest] = useState({});
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  

  useEffect(() => {
    handleGetManifest()
  }, [currentpage, size, searchDebounce]);

  useEffect(() => {
    setCurrentpage(1);
    handleGetManifest()
  }, [searchDebounce]);

  const handleGetManifest = () => {
    setIsLoadingAction(true);
    dispatch(
      handleGetAllManifest(
        id,
        {
          data: "manifest",
        },
        {
          keyword: keyword,
          page_number: currentpage,
          per_page: size,
          sort_column: "id",
          sort_order: "ASC",
        },
        (message) => {
          setIsLoadingAction(false);
          console.log("message:", message);
          setListDataManifest(message?.data);
        },
        (error) => {
          setIsLoadingAction(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  }

  const handleUploadManifest = (data, handleClosepoup = (data) => {}) => {
    setIsLoadingSave(true);
    dispatch(
      handleUploadAllManifest(
        id,
        data,
        (message) => {
          setIsLoadingSave(false);
          handleClosepoup();

          setIsLoadingAction(true);
          dispatch(
            handleGetAllManifest(
              id,
              {
                data: "manifest",
              },
              {
                keyword: "",
                page_number: currentpage,
                per_page: size,
                sort_column: "id",
                sort_order: "ASC",
              },
              (message) => {
                setIsLoadingAction(false);

                setListDataManifest(message?.data);
                dispatch(handleSuccess(message));
              },
              (error) => {
                setIsLoadingAction(false);
                dispatch(handleErrorBE(error));
              }
            )
          );
        },
        (error) => {
          setIsLoadingSave(false);

          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleExportManifest = async () => {
    setExporting(true)
    
    let response;
    if(activeTab === '1') {
      response = await exportManifestJamaah(id)
    } else {
      response = await exportManifestJamaah(id)
      // response = await exportManifestPembimbing(id)
    }
    
    setExporting(false)

    if(response.status === 200) {
      const { base64, type, file_name } = response.data.data;
      const insertPrefix = formattorbase64(type, base64);
      var a = document.createElement("a"); //Create <a>
      a.href = `${insertPrefix}`; //Image Base64 Goes here
      a.download = `${file_name}`; //File name Here
      a.click();
    } else {
      dispatch(handleError(response.data))
    }

  }

  const columns = [
    {
      title: "Foto",
      dataIndex: "photo",
      width: "32px",
      render: (image, data) => {
        return <Avatar value={data?.jamaah} />;
      },
    },
    {
      title: "Nama Sesuai KTP",
      dataIndex: "name",
      render: (text, data) => (
        <span>
          {toCapital(data?.jamaah?.title)}. {data?.jamaah?.name.toUpperCase()}
        </span>
      ),
    },
    {
      title: "NIK KTP",
      dataIndex: "manifest",
      render: (data) => (
        <span>
          {data?.ktp.data.ktp_nik}
        </span>
      ),
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "gender",
      width: "100px",
      render: (text, data) => <span>{data?.jamaah?.gender}</span>,
    },
    {
      title: "Nama Sesuai Passport",
      dataIndex: "manifest",
      render: (data) => (
        <span>
          {data?.passport.data.passport_name}
        </span>
      ),
    },
    {
      title: "No. Passport",
      dataIndex: "manifest",
      render: (data) => (
        <span>
          {data?.passport.data.passport_no}
        </span>
      ),
    },
    {
      title: "Starting",
      dataIndex: "starting",
      render: (val, data) => (
        <Badge
          label={data?.starting?.name}
          backgroundColor={data?.starting?.bg_color}
          color={data?.starting?.text_color}
        />
      ),
    },
    {
      title: "Jamaah Cabang",
      dataIndex: "branch",
      render: (val, data) => (
        <Badge
          label={data?.branch?.name?.toUpperCase()}
          backgroundColor={data?.branch?.bg_color}
          color={data?.branch?.text_color}
        />
      ),
    },
    {
      title: "Manifest",
      dataIndex: "key",
      render: (manifest, data) => (
        <PerlengkapanManifest
          data={data}
          setIsUpdateManifest={setIsUpdateManifest}
          setUpdateManifestKey={setUpdateManifestKey}
          setUpdateManifestTitle={setUpdateManifestTitle}
          setDataManifestSelected={(data) => {
            console.log("DATA SELECTED", data);
            setDataManifestSelected(data)
          }}
        />
      ),
    },
  ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: listDataManifest?.total_data,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const renderTab = () => {
    let tabs = [
      {
        label: (
          <>
            <div className="px-4 py-3">
              Jamaah
            </div>
          </>
        ),
        key: '1',
        onchange: (key) => {console.log(key)},
        children: (
          <>
            <MoslemeTable
              loading={isLoadingAction}
              columns={columns}
              data={listDataManifest?.data ?? []}
              pagination={pagination}
              customClass="overflow-auto z-0 !min-h-[600px]"
              onPageChange={(page) => setCurrentpage(page)}
              withSelection={true}
              onSizeChange={(currentpage, pageSize) =>
                onSizeChange(currentpage, pageSize)
              }
            />
          </>
        )
      }
    ]

    if(auth.user.access_data === 'all_branches') {
      tabs.push({
        label: (
          <>
            <div className="px-4">
              Pembimbing
            </div>
          </>
        ),
        key: '2',
        children: (
          <>
            <Pembimbing />
          </>
        )
      })
    }

    return tabs;
  }

  return (
    <>
    <Popup
      open={isUpdateManifest}
      handleClose={() => setIsUpdateManifest(false)}
      title={updateManifestTitle}
      width="900px" 
      children={
        <ManifestForm 
          manifestKey={updateManifestKey}
          dataManifest={dataManifestSelected}
          onUpdate={() => {
            handleGetManifest()
          }}
          />
        }
      />

     <div className="relative">
      <Tab 
          defaultActiveKey="1"
          onChange={(e) => setActiveTab(e)}
          items={renderTab()}
          />

        <div className="flex gap-4 items-center absolute top-2 right-4">
          {activeTab === '1' ? (
            <>
              <Inputs
                id="search"
                placeholder="Search"
                customClass="!w-[240px]"
                iconPosition="left"
                icon={IconSearch}
                onChange={e => setKeyword(e.target.value)}
              />
              <div onClick={handleExportManifest} className="cursor-pointer">
                {!exporting ? (
                  <Receive />
                ) : (
                  <div>
                    <Spinner width="16px" height="16px" />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="h-[54px] w-[240px]"></div>
          )}
        </div>
     </div>
    </>
  );
};

export default React.memo(PaketLaporanManifest);
