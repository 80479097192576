import React, { useCallback, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import CalculatorStyle from "./CalculatorStyle";
import SidebarChild from "../../components/template_layout/Siderbar/SidebarChild";

import RequestPaket from "./RequestPaket";
import UpdateHarga from "./UpdateHarga";

import Pesanan from "assets/icons/pesanan.svg";
import PesananRed from "assets/icons/pesanan-red.svg"
import TagihanGrey from "assets/icons/tagihan-grey.svg";
import TagihanRed from "assets/icons/tagihan-red.svg";

const PaketPage = () => {
  const [showContent, setShowContent] = useState("request_paket");

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;
  const [menuList, setMenuList] = useState([])

  const calculatorPacketMenuList = () => {
    let menu = [
      {
        title: "Request Paket",
        key: "request_paket",
        icon: Pesanan,
        iconActive: PesananRed,
        order: 0,
      }
    ];

    if (permissions?.includes('update_calculator-package')) {
      menu.push(
        {
          title: "Update Harga",
          key: "update_harga",
          icon: TagihanGrey,
          iconActive: TagihanRed,
          order: 1,
        })
    }

    setMenuList(menu)

  }
  

  const getContent = useCallback(() => {
    switch (showContent) {
      case "request_paket":
        return <RequestPaket />;
      default:
        return <UpdateHarga />;
    }
  }, [showContent]);

  useEffect(() => {
    calculatorPacketMenuList()
  }, [])

  return (
    <CalculatorStyle>
      <SidebarChild
        menuList={menuList}
        handleClick={setShowContent}
        showContent={showContent}
      />
      <div style={{ marginLeft: '174px', paddingLeft: '16px' }}>
        {getContent()}
      </div>
    </CalculatorStyle>
  );
};

export default PaketPage;
