import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import InputPhone from "../../../components/form/inputPhone";
import Inputs from "../../../components/form/inputs";
import Selects from "../../../components/form/selects";
import Popup from "../../../components/popup";
import FlagIndonesia from "../../../assets/icons/flag-indonesia.svg";
import ImageField from "../../../components/image_field";
import Buttons from "../../../components/buttons";
import AgenAction from "../../../stores/actions/member/agen";
import useDebounce from "utils/helpers/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { getRegion } from "../../../stores/actions/cabang";
import { useFormik } from "formik";
import { Select } from "antd";
import { Spin } from "antd";

import KTPPlaceholder from "../../../assets/icons/ktp_placeholder.svg";
import SelfiePlaceholder from "../../../assets/icons/selfie_placeholder.svg";
import { AgenMemberAPI } from "utils/api/member";
import uuid from "react-uuid";
import { setDataUpload } from "stores/actions/uploadFile";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import { getLocalStorage } from "utils/helpers";
import Upload from "pages/apps/components/Galeri/components/AddGaleri/components/Upload";
import UploadArea from "components/Uploadarea";
import Scroll from "components/scroll";

// placeholder component select agak susah di custome itu sih makan waktu karena auto select menyebabkan hilang

function CreateAgen({ show, close }) {
  const dispatch = useDispatch();
  const cabang = useSelector(({ cabang }) => cabang.dataAllCabang.data);
  const agen = useSelector(({ agen }) => agen);
  const [isLoadingDetailAgen, setIsLoadingDetailAgen] = useState(false);

  const [city, setCity] = useState("151");

  const id_agen = agen.id_agen;
  const modalTitle = id_agen !== "0" ? "Edit Data Agen" : "Tambah Data Agen";

  const optionStatusVerified = [
    {
      value: 1,
      label: "Verified",
    },
    {
      value: 0,
      label: "Unverified",
    },
  ]

  const branchOptions = cabang?.map((item) => ({
    value: item.id_cabang,
    label: item.name,
  }));

  const userLogin =
    getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));

  const accessData = userLogin?.data?.account?.access_data;

  const [titleData] = useState([
    {
      value: "Tn",
      label: "Tuan",
    },
    {
      value: "Ny",
      label: "Nyonya",
    },
    {
      value: "Nn",
      label: "Nona",
    },
  ]);

  const validation = useFormik({
    initialValues: {
      name: "",
      phone: "",
      cabang_id: "",
      email: null,
      password: null,
      province_id: "",
      city_id: "",
      subdistrict_id: "",
      profile_photo: "",
      foto_ktp: "",
      foto_selfie_ktp: "",
      no_ktp: "",
      is_email_confirmed: "",
      title: "",
      nickname: "",
      status_ktp: "",
    },
    validationSchema: Yup.object().shape(
      id_agen === "0"
        ? {
            name: Yup.string().required("Nama lengkap wajib diisi"),
            email: Yup.string().required("Email wajib diisi"),
            password: Yup.string().required("Kata sandi wajib diisi"),
            phone: Yup.string().required("Telepon wajib diisi"),
            no_ktp: Yup.string().required("No ktp wajib diisi"),
          }
        : {
            name: Yup.string().required("Nama lengkap wajib diisi"),
            email: Yup.string().required("Email wajib diisi"),
            phone: Yup.string().required("Telepon wajib diisi"),
            no_ktp: Yup.string().required("No ktp wajib diisi"),
          }
    ),
    onSubmit: (val) => {
      setIsloadingAdd(true);
      let payloadEdit = {
        id: id_agen,
        name: val.name,
        phone: val.phone,
        email: val.email,
        password: val.password || null,
        cabang_id: val.cabang_id,
        province_id: val.province_id,
        city_id: val.city_id,
        subdistrict_id: val.subdistrict_id,
        foto_ktp: val.foto_ktp,
        profile_photo: val.profile_photo,
        foto_selfie_ktp: val.foto_selfie_ktp,
        title: val.title,
        nickname: val.nickname,
        no_ktp: val.no_ktp,
        verified_ktp_at: val.verified_ktp_at,
        status_ktp: val.status_ktp,
        is_email_confirmed: val.is_email_confirmed,
      };
     
      dispatch(
        AgenAction.createData(
          id_agen === "0" ? val : payloadEdit,
          callbackPost,
          (message) => {
            setIsloadingAdd(false);
            dispatch(handleErrorBE(message));
          }
        )
      );
    },
  });

  const callbackPost = (message) => {
    setIsloadingAdd(false);

    dispatch(AgenAction.fetchData());
    dispatch(AgenAction.setAgenId("0"));
    close();
    dispatch(handleSuccess(null, { message, code: 200 }));
  };

  const { region } = useSelector((state) => state.cabang);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceLocation = useDebounce(searchTerm, 500);
  const [isLoadingAdd, setIsloadingAdd] = useState(false);

  useEffect(() => {
    if (debounceLocation !== "") {
      dispatch(
        getRegion({
          keyword: debounceLocation,
        })
      );
    }
  }, [debounceLocation]);

  useEffect(() => {
    if (id_agen !== "0") {
      setIsLoadingDetailAgen(true);
      AgenMemberAPI.getDetail(id_agen)
        .then((res) => {
          if (res.status === 200) {
            setIsLoadingDetailAgen(false);
            let results = res.data.data;
            setCity(results.city_id);
            setSearchTerm(results.cabang_name);
            validation.setFieldValue("name", results?.name);
            validation.setFieldValue("phone", results?.phone);
            validation.setFieldValue("cabang_id", results?.cabang_id);
            validation.setFieldValue("password", results?.password);
            validation.setFieldValue("province_id", results?.province_id);
            validation.setFieldValue("city_id", results?.city_id);
            validation.setFieldValue("subdistrict_id", results?.subdistrict_id);
            validation.setFieldValue("profile_photo", results?.profile_photo);
            validation.setFieldValue("foto_ktp", results?.foto_ktp);
            validation.setFieldValue(
              "foto_selfie_ktp",
              results?.foto_selfie_ktp
            );
            validation.setFieldValue("no_ktp", results?.no_ktp);
            validation.setFieldValue("email", results?.email);
            validation.setFieldValue(
              "is_email_confirmed",
              results?.is_email_confirmed
            );
            validation.setFieldValue("title", results?.title);
            validation.setFieldValue("nickname", results?.nickname);
            validation.setFieldValue("status_ktp", results?.status_ktp);

            let image = [
              {
                id: uuid(),
                fileName: "Profil photo",
                path: results.profile_photo,
                name: "profile_photo",
              },
              {
                id: uuid(),
                fileName: "Ktp photo",
                path: results.foto_ktp,
                name: "foto_ktp",
              },
              {
                id: uuid(),
                fileName: "Profil photo",
                path: results.foto_selfie_ktp,
                name: "foto_selfie_ktp",
              },
            ];

            dispatch(setDataUpload(image));
          }
        })
        .catch(() => {
          setIsLoadingDetailAgen(true);
        });
    } else {
      validation.resetForm();
    }
  }, [id_agen]);

  return (
    <Popup
      containerClass="!z-[1000]"
      width="1184px"
      open={show}
      handleClose={close}
      title={modalTitle}
    >
      <div className="border rounded-tr-2xl rounded-tl-2xl p-4">
        {!isLoadingDetailAgen ? (
            <Scroll height="calc(100vh-350px)">
              <div className="w-full grid grid-cols-2 gap-4">
                  <div>
                    <Inputs
                      name="name"
                      customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name}
                      label="Nama Lengkap"
                      placeholder="Masukan"
                      error={
                        validation.touched.name &&
                        validation.errors.name &&
                        validation.errors.name
                      }
                    />
                    <div className="grid grid-cols-3 gap-3 mt-4">
                      <Selects
                        label="Title"
                        customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                        placeholder="Select item(s)"
                        values={titleData.filter(
                          item => item?.value == validation.values?.title
                        )}
                        onChange={(e) => validation.setFieldValue("title", e[0]?.value)}
                        options={titleData}
                        error={
                          validation.touched.title &&
                          validation.errors.title &&
                          validation.errors.title
                        }
                      />

                      <div className="col-span-2">
                        <Inputs
                          customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                          label="Nama Panggilan"
                          placeholder="Masukan"
                          name="nickname"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.nickname}
                          error={
                            validation.touched.nickname &&
                            validation.errors.nickname &&
                            validation.errors.nickname
                          }
                        />
                      </div>
                    </div>

                    <div className="mt-3">
                      <Inputs
                        label="Email"
                        name="email"
                        customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                        placeholder="Masukan"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email}
                        error={
                          validation.touched.email &&
                          validation.errors.email &&
                          validation.errors.email
                        }
                      />
                    </div>

                    <div className="mt-3">
                      <InputPhone
                        label="Nomor Telepon"
                        placeholder="Masukan"
                        name="phone"
                        customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                        value={validation.values.phone}
                        error={
                          validation.touched.phone &&
                          validation.errors.phone &&
                          validation.errors.phone
                        }
                        onChange={(value) => validation.setFieldValue("phone", value)}
                        onBlur={validation.handleBlur}
                        phoneRegion="'+62'"
                        containerClass="mt-2"
                        icon={FlagIndonesia}
                        enableSearch
                      />
                    </div>

                    <div className="mt-3">
                      <Inputs
                        type="password"
                        label="Kata Sandi"
                        placeholder="Masukan"
                        name="password"
                        customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password}
                        error={
                          validation.touched.password &&
                          validation.errors.password &&
                          validation.errors.password
                        }
                      />
                    </div>

                    <div className="mt-3">
                      <Selects
                        customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                        label="Status Email"
                        placeholder="Select item(s)"
                        values={optionStatusVerified.filter(item => item.value == validation.values.is_email_confirmed)}
                        onChange={(e) =>
                          validation.setFieldValue("is_email_confirmed", e[0].value)
                        }
                        options={optionStatusVerified}
                      />
                    </div>

                    {accessData !== "self_branch" && (
                      <div className="mt-3">
                        <Selects
                          customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                          label="Cabang"
                          placeholder="Select item(s)"
                          options={branchOptions}
                          values={branchOptions.filter(
                            (item) => item.value === validation.values.cabang_id
                          )}
                          onChange={(val) => {
                            validation.setFieldValue("cabang_id", val[0]?.value);
                          }}
                        />
                      </div>
                    )}
                    <div className="mt-3">
                      <Inputs
                        customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                        type="text"
                        label="Nomor Induk KTP"
                        placeholder="Masukan no NIK"
                        name="no_ktp"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.no_ktp}
                        error={
                          validation.touched.no_ktp &&
                          validation.errors.no_ktp &&
                          validation.errors.no_ktp
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <Selects
                        customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                        label="Status Verifikasi KTP"
                        placeholder="Select item(s)"
                        values={optionStatusVerified.filter(
                          item => item.value == validation.values.status_ktp
                        )}
                        onChange={(e) =>
                          validation.setFieldValue("status_ktp", e[0].value)
                        }
                        options={optionStatusVerified}
                      />
                    </div>
                  </div>

                  <div>
                    {/* <ImageField
                      customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                      onBlur={validation.handleBlur}
                      name="profile_photo"
                      customContainer="h-[248px]"
                      setFile={(file) =>
                        validation.setFieldValue("profile_photo", file?.path)
                      }
                      label="Foto Profil"
                    /> */}
                    <UploadArea 
                      defaultUrl={validation.values?.profile_photo}
                      onChange={(file) => validation.setFieldValue("profile_photo", file)}
                      label="Foto Profil"
                      customClass="!h-[250px] !w-[250px]"
                      type="chose"
                      choseType="image"
                      />
                    <div className="mt-3">
                      {/* <ImageField
                        customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                        name="foto_ktp"
                        customContainer="h-[248px]"
                        setFile={(file) =>
                          validation.setFieldValue("foto_ktp", file?.path)
                        }
                        label="Foto KTP"
                        customPlaceholder={<img src={KTPPlaceholder} alt="ktp" />}
                      /> */}
                      <UploadArea 
                        defaultUrl={validation.values?.foto_ktp}
                        onChange={(file) => validation.setFieldValue("foto_ktp", file)}
                        label="Foto KTP"
                        type="chose"
                        choseType="image"
                      />
                    </div>
                    <div className="mt-3">
                      {/* <ImageField
                        customeLabel="!font-normal !text-[12px] !text-[#4F4F4F] !mb-[8px]"
                        name="foto_selfie_ktp"
                        customContainer="h-[248px]"
                        setFile={(file) =>
                          validation.setFieldValue("foto_selfie_ktp", file?.path)
                        }
                        label="Foto Selfie KTP"
                        customPlaceholder={<img src={SelfiePlaceholder} alt="selfie" />}
                      /> */}
                      <UploadArea 
                        defaultUrl={validation.values?.foto_selfie_ktp}
                        onChange={(file) => validation.setFieldValue("foto_selfie_ktp", file)}
                        label="Foto Selfie KTP"
                        type="chose"
                        choseType="image"
                      />
                    </div>

                    
                  </div>
              </div>
            </Scroll>
        ) : (
          <div className="w-full h-[530px] flex justify-center items-center">
            <Spin size="large" />
          </div>
        )}
      </div>
      <div className="border rounded-br-2xl rounded-bl-2xl p-4 border-t-0 flex justify-end">
        <Buttons
          loading={isLoadingAdd}
          disabled={!(validation.isValid && validation.dirty)}
          onClick={() => validation.handleSubmit()}
          text="Simpan"
          customClass="btn-primary btn-large"
        />
      </div>
    </Popup>
  );
}

export default CreateAgen;
