
import { useSelector } from 'react-redux'

import Avatar from "components/avatar"
import Badge from "components/badge"
import Buttons from "components/buttons"

import Dropdowns, { closeDropdown } from "components/dropdowns"
import Inputs from "components/form/inputs"
import InputsCurrency from "components/form/inputsCurrency"
import Textarea from "components/form/textarea"
import Scroll from "components/scroll"
import { toCapital } from 'utils/helpers'
import { useFormik } from 'formik'

import * as Yup from 'yup'
import { useEffect, useState } from 'react'

const AdditionalJamaahForm = ({
    order,
    index,
    additionalsFormik,
    handleSave
}) => {
    const { bookingDataJamaah } = useSelector((state) => state.booking);
    const [jamaahSelectorLabel, setJamaahSelectorLabel] = useState('Pilih Jamaah')

    const jamaahFormik = useFormik({
        initialValues: {
            order_kamar_mapping_id: null,
            quantity: 1,
            note: null
        },
        validationSchema: Yup.object().shape({
            order_kamar_mapping_id: Yup.number().required('Harus pilih jamaah'),
            quantity: Yup.number().required('Quantity harus diisi dan harus berupa angka')
        })
    })

    const handleJamaahSelecting = (jamaah) => {
        jamaahFormik.setFieldValue('order_kamar_mapping_id', jamaah.id)
        jamaahFormik.setFieldValue('name', jamaah.jamaah_name)
        jamaahFormik.setFieldValue('title', jamaah.jamaah_title)
        jamaahFormik.setFieldValue('starting_name', jamaah.name_package_starting)
        jamaahFormik.setFieldValue('starting_bg_color', jamaah.package_starting?.bg_color)
        jamaahFormik.setFieldValue('starting_text_color', jamaah.package_starting?.text_color)
        jamaahFormik.setFieldValue('jamaah_photo', jamaah.jamaah_photo)

        setTimeout(() => {
            jamaahFormik.setFieldTouched('order_kamar_mapping_id', 1)
            jamaahFormik.setFieldTouched('name', 1)
            jamaahFormik.setFieldTouched('title', 1)
            jamaahFormik.setFieldTouched('starting_name', 1)
            jamaahFormik.setFieldTouched('starting_bg_color', 1)
            jamaahFormik.setFieldTouched('starting_text_color', 1)
            jamaahFormik.setFieldTouched('jamaah_photo', 1)
        })

        setJamaahSelectorLabel(`${toCapital(jamaah.jamaah_title)}. ${jamaah.jamaah_name}`)

        closeDropdown()

    }

    const handleAddAditional = () => {
        additionalsFormik.setFieldValue(`list_jamaah[${index}].order_kamar_mapping_id`, jamaahFormik.values.order_kamar_mapping_id)
        additionalsFormik.setFieldValue(`list_jamaah[${index}].quantity`, jamaahFormik.values.quantity)
        additionalsFormik.setFieldValue(`list_jamaah[${index}].note`, jamaahFormik.values.note)
        additionalsFormik.setFieldValue(`list_jamaah[${index}].name`, jamaahFormik.values.name)
        additionalsFormik.setFieldValue(`list_jamaah[${index}].title`, jamaahFormik.values.title )
        additionalsFormik.setFieldValue(`list_jamaah[${index}].starting_name`, jamaahFormik.values.starting_name)
        additionalsFormik.setFieldValue(`list_jamaah[${index}].starting_bg_color`, jamaahFormik.values.starting_bg_color)
        additionalsFormik.setFieldValue(`list_jamaah[${index}].starting_text_color`, jamaahFormik.values.starting_text_color)
        additionalsFormik.setFieldValue(`list_jamaah[${index}].jamaah_photo`, jamaahFormik.values.photo)
        

        setTimeout(() => {
            additionalsFormik.setFieldTouched(`list_jamaah[${index}].order_kamar_mapping_id`, 1)
            additionalsFormik.setFieldTouched(`list_jamaah[${index}].quantity`, 1)
            additionalsFormik.setFieldTouched(`list_jamaah[${index}].note`, 1)
            additionalsFormik.setFieldTouched(`list_jamaah[${index}].name`, 1)
            additionalsFormik.setFieldTouched(`list_jamaah[${index}].title`, 1)
            additionalsFormik.setFieldTouched(`list_jamaah[${index}].starting_name`, 1)
            additionalsFormik.setFieldTouched(`list_jamaah[${index}].starting_bg_color`, 1)
            additionalsFormik.setFieldTouched(`list_jamaah[${index}].starting_text_color`, 1)
            additionalsFormik.setFieldTouched(`list_jamaah[${index}].jamaah_photo`, 1)
        })
        handleSave()

    }

    useEffect(() => {
        if(additionalsFormik.values.list_jamaah[index].order_kamar_mapping_id) {
            jamaahFormik.setFieldValue('order_kamar_mapping_id', additionalsFormik.values.list_jamaah[index].order_kamar_mapping_id)
            jamaahFormik.setFieldValue('quantity', additionalsFormik.values.list_jamaah[index].quantity)
            jamaahFormik.setFieldValue('note', additionalsFormik.values.list_jamaah[index].note)
            jamaahFormik.setFieldValue(`name`, additionalsFormik.values.list_jamaah[index].name)
            jamaahFormik.setFieldValue(`title`, additionalsFormik.values.list_jamaah[index].title )
            jamaahFormik.setFieldValue(`starting_name`, additionalsFormik.values.list_jamaah[index].starting_name)
            jamaahFormik.setFieldValue(`starting_bg_color`, additionalsFormik.values.list_jamaah[index].starting_bg_color)
            jamaahFormik.setFieldValue(`starting_text_color`, additionalsFormik.values.list_jamaah[index].starting_text_color)
            jamaahFormik.setFieldValue(`jamaah_photo`, additionalsFormik.values.list_jamaah[index].photo) 

            setTimeout(() => {
                jamaahFormik.setFieldValue('order_kamar_mapping_id', additionalsFormik.values.list_jamaah[index].order_kamar_mapping_id)
                jamaahFormik.setFieldValue('quantity', additionalsFormik.values.list_jamaah[index].quantity)
                jamaahFormik.setFieldValue('note', additionalsFormik.values.list_jamaah[index].note)
                jamaahFormik.setFieldValue(`name`, additionalsFormik.values.list_jamaah[index].name)
                jamaahFormik.setFieldValue(`title`, additionalsFormik.values.list_jamaah[index].title )
                jamaahFormik.setFieldValue(`starting_name`, additionalsFormik.values.list_jamaah[index].starting_name)
                jamaahFormik.setFieldValue(`starting_bg_color`, additionalsFormik.values.list_jamaah[index].starting_bg_color)
                jamaahFormik.setFieldValue(`starting_text_color`, additionalsFormik.values.list_jamaah[index].starting_text_color)
                jamaahFormik.setFieldValue(`jamaah_photo`, additionalsFormik.values.list_jamaah[index].photo)
            }, [])

            setJamaahSelectorLabel(`${toCapital(additionalsFormik.values.list_jamaah[index].title)}. ${additionalsFormik.values.list_jamaah[index].name}`)
       }
    }, [])

    return (
        <>
            <div className="bg-white rounded-xl p-4 mt-4">
                <Dropdowns
                    customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between items-center`}
                    placement="bottom"
                    labelDropdown={jamaahSelectorLabel}
                    dropdownArrow={true}
                >
                    <div className="bg-white w-full soft-shadow rounded-2xl overflow-hidden">
                    <Scroll height="350px">
                        {bookingDataJamaah.length && bookingDataJamaah.map((jamaah, index) => (
                            <>
                                <div 
                                onClick={() => handleJamaahSelecting(jamaah)}
                                className="p-4 hover:bg-red-5 border-b border-gray-4 cursor-pointer active:bg-[#FF9C9A]">
                                    <div className="flex items-center gap-2">
                                        <Avatar 
                                            value={jamaah}
                                            containerClass={`items-center justify-center  w-[40px] h-[40px] rounded-[100%]  bg-slate-100`}
                                            customClass={`object-cover w-[40px] h-[40px] rounded-[100%]`}
                                            />
                                        <div className="flex flex-col gap-1">
                                            <div>
                                                {jamaah.jamaah_title ? `${toCapital(jamaah.jamaah_title)}. ` : ``} {jamaah.jamaah_name.toUpperCase()}
                                            </div>
                                            <div className="flex gap-2">
                                                <Badge 
                                                    label={order?.cabang?.name}
                                                    color={order?.cabang?.color_text}
                                                    backgroundColor={order?.cabang?.color}
                                                    />
                                                <Badge 
                                                    label={jamaah?.name_package_starting}
                                                    color={jamaah?.package_starting?.text_color}
                                                    backgroundColor={jamaah?.package_starting?.bg_color}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </Scroll>
                    </div>
                </Dropdowns>

                <div className="flex gap-2 items-center mt-4">
                    <Inputs 
                        id="quantity"
                        type='number'
                        containerClass="w-1/5"
                        label="Jumlah"
                        placeholder="Jumlah"
                        min={1}
                        onChange={jamaahFormik.handleChange}
                        onBlur={jamaahFormik.handleBlur}
                        value={jamaahFormik.values.quantity}
                        error={jamaahFormik.touched.quantity && jamaahFormik.errors.quantity}
                        />
                    <InputsCurrency
                        id="price" 
                        containerClass="w-4/5"
                        label="Harga"
                        disabled
                        value={Math.round(jamaahFormik.values.quantity * (additionalsFormik.values.paket_additional_price ?? 0))}
                     />
                </div>
                <div className="mt-4 mb-4">
                    <Textarea
                        label="Catatan"
                        id="note"
                        placeholder="Catatan"
                        onChange={jamaahFormik.handleChange}
                        onBlur={jamaahFormik.handleBlur}
                        value={jamaahFormik.values.note}
                        error={jamaahFormik.touched.note && jamaahFormik.errors.note}
                        ></Textarea>
                </div>
                <Buttons
                    text="Simpan Jamaah"
                    customClass="btn btn-primary btn-medium"
                    disabled={jamaahFormik.dirty && !jamaahFormik.isValid}
                    onClick={handleAddAditional}
                    />

            </div>
        </>
    )
}

export default AdditionalJamaahForm