import { makeStyles } from "@material-ui/core";
import moment from "moment";

import CalendarIcon from "../../../../../../assets/icons/colored/calendar-icon.svg";
import ClockIcon from "../../../../../../assets/icons/colored/clock-icon.svg";
import AirplaneIcon from "../../../../../../assets/icons/colored/airplane-icon.svg";
import Airplane2Icon from "../../../../../../assets/icons/colored/airplane-2-icon.svg";
import CategoryIcon from "../../../../../../assets/icons/colored/category-icon.svg";
import TicketingIcon from "../../../../../../assets/icons/colored/ticketing-icon.svg";
import TotalSeatIcon from "../../../../../../assets/icons/colored/total-seat-icon.svg";
import FilledSeatIcon from "../../../../../../assets/icons/colored/filled-seat-icon.svg";
import AvailableSeatIcon from "../../../../../../assets/icons/colored/available-seat-icon.svg";
import ImageUmrah from "../../../../../../assets/images/image_umrah.png";

const useStyle = makeStyles({
  paketCard: {
    background: "#FBF3F2",
    borderRadius: "40px",
    cursor: "pointer",
    fontSize: "General Sans, sans-serif",
    padding: "51px 12px 12px 12px",
    position: "relative",
    zIndex: 1,
    "&:nth-child(1)": {
      borderRadius: "0px 0px 40px 40px",
    },
    "& .card_content": {
      background: "#fff",
      borderRadius: "32px",
      boxShadow:
        "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
      display: "flex",
      padding: "10px 10px 12px 12px",
    },
    "& .card_image_wrapper": {
      borderRadius: "24px",
      boxShadow: "0px 6px 64px -4px rgba(0, 0, 0, 0.04)",
      filter:
        "drop-shadow(0px 10px 16px rgba(0, 0, 0, 0.04)) drop-shadow(0px 6px 32px rgba(0, 0, 0, 0.04))",
      overflow: "hidden",
      position: "relative",
      marginTop: "-38px",
      "& .card_image": {
        height: "248px",
        objectFit: "cover",
        width: "200px !important",
      },
    },
    "& .card_text_wrapper": {
      paddingLeft: "12px",
      width: "100%",
      "& .card_text_title": {
        borderBottom: "1px dashed #BDBDBD",
        borderSpacing: "5px",
        paddingBottom: "8px",
        position: "relative",
        "& h2": {
          fontWeight: 600,
          fontSize: "14px",
          textTransform: "uppercase",
          lineHeight: "20px",
        },
        "& .card_badge": {
          background: "#2eb24333",
          borderRadius: "4px",
          color: "#333D29",
          fontSize: "8px",
          fontWeight: "600",
          padding: "4px 8px",
          position: "absolute",
          bottom: "10px",
          right: "0",
          width: "fit-content",
        },
      },
      "& .card_detail_item": {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        marginTop: "12px",
        fontSize: "12px",
        "& .green_bold_text": {
          background:
            "linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%)",
          fontWeight: 700,
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
        },
      },
    },
  },
});

const PaketCard = ({ data }) => {
  const classes = useStyle();

  function numberWithDot(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const dateFormatter = (val) => {
    return moment(val).format("DD MMMM YYYY");
  };

  const moneyFormatter = (val) => {
    return new Intl.NumberFormat().format(val);
  };

  const conditionalPackageType = data.package_type === "1" ? "VIP" : data.package_type === "2" ? "Hemat" : data.package_type === "3" ? "Superhemat" : data.package_type === "4" ? "Extra Hemat" : ""

  return (
    <>
      <div>
        <div className="card_content">
          <div className="card_image_wrapper">
            <img
              src={ImageUmrah}
              alt="card image"
              className="card_image"
            />
            <div className="absolute bottom-0 w-full">
              <div className="bg-white rounded-t-lg w-fit p-2">
                <img src={Airplane2Icon} alt="icon" width={16} height={16} />
              </div>
              <div className="backdrop-blur bg-black/25 px-3 py-1 w-full">
                <div className="flex gap-2 items-center text-white text-xs">
                  <img src={CalendarIcon} alt="icon" width={16} height={16} />
                  {data?.year} M/{parseInt(data?.year) - 578} H
                </div>
                <div className="flex gap-2 items-center text-white text-xs mt-2">
                  <img src={CategoryIcon} alt="icon" width={16} height={16} />
                  {conditionalPackageType}
                </div>
              </div>
              <div className="bg-white flex justify-between w-full  p-3">
                <div className="flex gap-1 text-sm">
                  <img src={TotalSeatIcon} alt="icon" />
                  {data.kuota || "-"}
                </div>
                <div className="flex gap-1 text-sm text-blue-500">
                  <img src={FilledSeatIcon} alt="icon" />
                  {data.jumlah_jamaah || "-"}
                </div>
                <div className="flex gap-1 text-sm text-green-500">
                  <img src={AvailableSeatIcon} alt="icon" />
                  {data.kuota_tersedia || "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="card_text_wrapper">
            <div className="card_text_title">
              <h2>{data.package_title}</h2>
              <div className="card_badge">
                {" "}
                {data?.status === 0 ? "Draft" : "Publish"}
              </div>
            </div>
            <div className="card_detail_item">
              <img src={CalendarIcon} alt="icon" width={16} height={16} />
              {dateFormatter(data.jadwal_keberangkatan)}
            </div>
            <div className="card_detail_item">
              <img src={ClockIcon} alt="icon" width={16} height={16} />
              {data?.number_of_days} Hari
            </div>
            <div className="card_detail_item">
              <img src={AirplaneIcon} alt="icon" width={16} height={16} />
              <p className="title-paket">{data?.maskapai_name}</p>
            </div>
            <div className="card_detail_item">
              <img src={TicketingIcon} alt="icon" width={16} height={16} />
              <span className="green_bold_text">
                Rp {numberWithDot(data?.total_price)}
              </span>
              /pax
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaketCard;
