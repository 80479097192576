import Buttons from "components/buttons";
import Dropdowns, { closeDropdown } from "components/dropdowns";
import Inputs from "components/form/inputs";
import InputsCurrency from "components/form/inputsCurrency";
import Radio from "components/form/radio";
import Scroll from "components/scroll";
import { Eye, EyeSlash } from "iconsax-react";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { handleError } from "stores/actions/errorGeneral";

import { PaketCouponAPI } from "utils/api/paket/coupon";

import { IDRFormater, toCurrencyFormat } from "utils/helpers";

const Step4 = ({ packageID, bookingForm, additionalsFormik, handleSetTotalPrice, packageSelected }) => {
  const dispatch = useDispatch()
  const [couponSelectorLabel, setCouponSelectorLabel] = useState("Pakai Kupon")

  const [couponList, setCouponList] = useState([])
  const [couponPrivateList, setCouponPrivateList] = useState([])
  const [isLoadingCheckCoupon, setIsloadingCheckCoupon] = useState(false)
  const [selectedCoupon, setSelectedCoupon] = useState({})

  const [couponSelected, setCouponSelected] = useState({})

  const [typeCoupon, setTypeCoupon] = useState(1)
  const [codeCoupon, setCodeCoupon] = useState(null)

  const [priceOrderPax, setPriceOrderPax] = useState(0)
  const [priceAdditionalTotal, setPriceAdditionalTotal] = useState(0)
  const [discountPrice, setDiscountPrice] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)


  const [typeOptions, setType] = useState([
    {
      value: (
        <div className="flex items-center">
          <Eye variant="Bold" className="mr-2" /> <p>Public</p>
        </div>
      ),
      label: "Pilih Kupon",
      key: 1,
    },
    {
      value: (
        <div className="flex items-center">
          <EyeSlash variant="Bold" className="mr-2" /> <p>Private</p>
        </div>
      ),
      label: "Masukan Kupon",
      key: 2,
    },
  ]);

  const handleGetCoupons = async () => {
    const params = {
      code_coupon: null,
      package_id: packageID
    }
    const response = await PaketCouponAPI.getCouponForOrder(params)
    const { status, data } = response

    if(status === 200) {
      setCouponList(data.data)
    } else {
      dispatch(handleError(data))
    }

  }

  const handleGetCouponPrivate = async () => {
    setCouponPrivateList([])
    if(codeCoupon != '') {
      const params = {
        code_coupon: codeCoupon,
        package_id: packageID
      }
      setIsloadingCheckCoupon(true)
      const response = await PaketCouponAPI.getCouponForOrder(params)
      const { status, data } = response
      setIsloadingCheckCoupon(false)
      if(status === 200) {
        setCouponPrivateList(data.data)
      } else {
        dispatch(handleError(data))
      }  
    }
  }

  const handleSelectCoupon = (coupon) => {
    
    setSelectedCoupon(coupon)
    // handleCountTotalCutPrice()

    bookingForm.setFieldValue('id_coupon', coupon.id_coupon)
    bookingForm.setFieldValue('coupon', coupon)

    setTimeout(() => {
      bookingForm.setFieldTouched('id_coupon', 1)
      bookingForm.setFieldTouched('coupon', 1)
    })

    const label = `${coupon.code_coupon} - Diskon ${coupon.jenis_diskon === 1 ? toCurrencyFormat(coupon.nominal_diskon, packageSelected.currency) : `${coupon.nominal_diskon}%`}`
    setCouponSelectorLabel(label)

    closeDropdown()
  }

  const handleResetCoupon = () => {
    bookingForm.setFieldValue('id_coupon', null)
    bookingForm.setFieldValue('coupon', null)

    setTimeout(() => {
      bookingForm.setFieldTouched('id_coupon', 1)
      bookingForm.setFieldTouched('coupon', 1)
    })

    const label = `Pakai Kupon`
    setCouponSelectorLabel(label)

  }

  const handleCountOrderJamaah = () => {
    let totalHargaOrder = 0;

    for(const order of bookingForm.values.order_kamars) {
      const paketKamar = order.paket_kamar;
      const price = paketKamar ? paketKamar.price : 0; // Menggunakan harga paket kamar jika ada, 0 jika tidak

      // Menambahkan total harga paket kamar
      totalHargaOrder += price;
    }

    setPriceOrderPax(totalHargaOrder)
  }

  const handleCountAdditionalPrice = () => {

    const additionals = additionalsFormik.values.paket_additionals
    
    let additionalPrice = 0;

    for(const additional of additionals) {
      const price = additional ? additional.price : 0; // Menggunakan harga paket kamar jika ada, 0 jika tidak
      let quantity = 0;

      for (const jamaah of additional.jamaah) {
        quantity += jamaah.quantity
      }

      // Menambahkan total harga paket kamar
      additionalPrice += (price * quantity);
    }

    setPriceAdditionalTotal(additionalPrice)
  }

  const handleCountTotalCutPrice = () => {
    const discountCoupon = parseInt(bookingForm.values.coupon?.nominal_diskon ?? 0)
    let discount = 0;
    
    if(bookingForm.values.coupon?.jenis_diskon === 1) {
      discount = discountCoupon;
    } else {
      const priceBeforeCutting = priceOrderPax +  priceAdditionalTotal
      discount = priceBeforeCutting * ( discountCoupon / 100 ) 
    }
    // console.log("Discount", discount, discountCoupon)

    const agentDiscount = parseInt(bookingForm.values?.agent_discount_amount ?? 0) 
    
    setDiscountPrice(discount + agentDiscount)
  }

  const handleCountTotalPrice = () => {
    let countTotalPrice = priceOrderPax + priceAdditionalTotal - discountPrice;

    handleSetTotalPrice(countTotalPrice)
    setTotalPrice(countTotalPrice)
  }

  useEffect(() => {
    handleCountTotalCutPrice()
    handleCountTotalPrice()
  }, [bookingForm.values.id_coupon, bookingForm.values.agent_discount_amount, priceAdditionalTotal, priceOrderPax, discountPrice, selectedCoupon])

  useEffect(() => {
    // setSelectedCoupon({})
    handleGetCoupons()

    handleCountOrderJamaah()
    handleCountAdditionalPrice()
    handleCountTotalPrice()

    if(bookingForm.values.id_coupon) {
      const coupon = bookingForm.values.coupon
      const label = `${coupon.code_coupon} - Diskon ${coupon.jenis_diskon === 1 ? toCurrencyFormat(coupon.nominal_diskon, packageSelected.currency, false) : `${coupon.nominal_diskon}%`}`
      setCouponSelectorLabel(label)
    }

  }, [])

  return (
      <>  
          <div className="mb-4">
            <div className="font-semibold text-sm mb-2 flex justify-between">
              <div>Kupon</div>
              <div onClick={handleResetCoupon} className="cursor-pointer text-red-1">Reset</div>
            </div>
            <Dropdowns
              customClass={`bg-[#f2f2f2] p-4 rounded-xl flex mt-2 justify-between  items-center`}
              placement="bottom"
              labelDropdown={couponSelectorLabel}
              dropdownArrow={true}
            >

              <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
                <div>
                  <Radio 
                    options={typeOptions} 
                    onChange={(e) => {
                      const selectedType = typeOptions.find(
                        (option) => option.value === e.target.value
                      )

                      if(selectedType) {
                        setTypeCoupon(selectedType.key);
                      }

                    }}
                    />
                </div>
                {typeCoupon === 1 && (
                  <>
                    <Scroll 
                      height="350px"
                      customClass="mt-4 pr-4"
                      >
                      {couponList?.map((coupon, index) => {
                        return (
                          <>
                            <div 
                              onClick={() => handleSelectCoupon(coupon)}
                              className="border p-4 rounded-xl mb-4 cursor-pointer">
                              <div className=" text-xl font-semibold mb-2">
                                {coupon.code_coupon}
                              </div>
                              <div className="text-base text-[#F2994A]">
                                {coupon.jenis_diskon === 1 && (
                                  <>
                                    Diskon {toCurrencyFormat(coupon?.nominal_diskon, packageSelected.currency, false)}
                                  </>
                                )}
                                {coupon.jenis_diskon === 2 && (
                                  <>
                                    Diskon {coupon.nominal_diskon}%
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </Scroll>
                  </>
                )}
                {typeCoupon === 2 && (
                  <>
                    <div className="flex items-center gap-2 mt-4">
                      <div className="w-4/5">
                        <Inputs 
                          placeholder="Masukan Kode Kupon"
                          onChange={(e) => setCodeCoupon(e.target.value)}
                          />
                      </div>
                      <div className="w-1/5">
                        <Buttons
                          text="Cek Kupon"
                          customClass="btn btn-primary btn-medium !py-4"
                          onClick={() => handleGetCouponPrivate()}
                          loading={isLoadingCheckCoupon}
                          />
                      </div>
                    </div>
                    <div className="mt-4">
                      {couponPrivateList?.map((coupon, index) => {
                          return (
                            <>
                              <div 
                                onClick={() => handleSelectCoupon(coupon)}
                                className="border p-4 rounded-xl mb-4 cursor-pointer">
                                <div className=" text-xl font-semibold mb-2">
                                  {coupon.code_coupon}
                                </div>
                                <div className="text-base text-[#F2994A]">
                                  {coupon.jenis_diskon === 1 && (
                                    <>
                                      Diskon {toCurrencyFormat(coupon?.nominal_diskon, packageSelected.currency, false)}
                                    </>
                                  )}
                                  {coupon.jenis_diskon === 2 && (
                                    <>
                                      Diskon {coupon.nominal_diskon}%
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          )
                        })}
                    </div>
                  </>
                )}
              </div>

            </Dropdowns>
          </div>
          {bookingForm.values.pemesan.guard_type === 'agent' && (
            <>
              <div>
                <InputsCurrency 
                  label="Diskon Agen (akan mengurangi fee agen)"
                  defaultValue={bookingForm.values.agent_discount_amount}
                  onChange={(val) => {
                    bookingForm.setFieldValue('agent_discount_amount', val)

                    setTimeout(() => {
                      bookingForm.setFieldTouched('agent_discount_amount', 1)
                    })

                  }}
                  />
              </div>
            </>
          )}

          <div className="mt-4 border rounded-2xl p-4">
            <h3 className="text-base font-semibold mb-4">Rincian Booking</h3>

            <div className=" text-sm">
              <div className="flex justify-between py-1">
                <div>
                  Harga Total Pax
                </div>
                <div className="font-semibold text-base">
                  {toCurrencyFormat(priceOrderPax, packageSelected.currency, false)}
                </div>
              </div>
              <div className="flex justify-between py-1">
                <div>
                  Total Additional
                </div>
                <div className="font-semibold text-base">
                  {toCurrencyFormat(priceAdditionalTotal, packageSelected.currency, false)}
                </div>
              </div>
              <div className="flex justify-between py-1">
                <div>
                  Potongan Harga
                </div>
                <div className="font-semibold text-base text-red-1">
                  - {toCurrencyFormat(discountPrice, packageSelected.currency, false)}
                </div>
              </div>
            </div>

          </div>
      </>
  );
};

export default Step4;