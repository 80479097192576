import axios from "axios";
import { BASE_URL_ENDPOINT, BASE_URL_ENDPOINT_V2 } from "constant/constanta";

const BookingUmrohAPI = {
  getList: (payload) => axios.post(`${BASE_URL_ENDPOINT}/admin/order`, payload),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-order`, payload),
};

const handleGetBookingList = (params) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/orders`, {params});

const handleGetJamaahBookingList = (params) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/orders/jamaah`, {params});

const handleUpdateJamaahOrderAPI = (orderNumber, kamarMappingID, payload) => 
  axios.put(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/${kamarMappingID}`, payload)

const handleStoreBookingAPI = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/order`, payload);

const handleCancelBooking = (orderNumber, payload) => 
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/cancel`, payload)

// DETAIL
const handleGetBookingDetail = (orderNumber) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/details`)

const handleGetDataJamaahAPI = (orderNumber) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/data-jamaah`)

const handleStoreDataJamaahAPI = (orderNumber, payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/add-jamaah`, payload)

const handleGetDataManifestAPI = ({orderNumber, jamaahOrderID}) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/${jamaahOrderID}/manifests`)

  
const handleGetDataAdditionalsAPI = ({orderNumber, jamaahOrderID}) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/${jamaahOrderID}/additionals`)

const handleGetDataEquipmentsAPI = ({orderNumber, jamaahOrderID}) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/${jamaahOrderID}/equipments`)

const handleAddAdditionalAPI = (orderNumber, payload) => 
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/add-additional`, payload)

const handleUpdateAdditionalByJamaahOrderAPI=({ orderNumber, jamaahOrderID, additionalID, payload }) => 
  axios.put(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/${jamaahOrderID}/additional-package/${additionalID}`, payload)

const handleDeleteAdditionalByJamaahOrderAPI=({ orderNumber, jamaahOrderID, additionalID }) => 
  axios.delete(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/${jamaahOrderID}/additional-package/${additionalID}`)

const handleUpdateEquipmentsAPI = ({orderNumber, jamaahOrderID, payload}) =>
  axios.put(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/${jamaahOrderID}/update-equipments`, payload)

const handleUpdateManifestAPI = (orderNumber, payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/manifests/upload`, payload)

const handleGetPaymentsAPI = (orderNumber, params) => 
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/payments`, {params})
const handleStorePaymentAPI = (orderNumber, payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/payments`, payload)

const handleUpdatePaymentAPI = (orderNumber, paymentID, payload) =>
  axios.put(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/payment/${paymentID}`, payload)

const handleUpdateDiscountAPI = (orderNumber, payload) =>
  axios.put(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/update-discount`, payload)

const handleGetAccountJamaahAPI = (phoneNumber) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/orders/account-jamaah/chose-accounts?phone_number=${phoneNumber}`)

const handleRegisterAccountJamaahAPI = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/orders/account-jamaah/register-account`, payload)

const handleCancelJamaahAPI = (orderNumber, jamaahOrderID) =>
  axios.delete(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/${jamaahOrderID}`)

const handleGenerateEBuktiBooking = (orderNumber) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/e-booking-receipt/generate`)

const handleSendFollowup = (orderNumber, key) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/order/${orderNumber}/followup/${key}/send`);

const handleSendFollowupToJamaahOrder = (orderNumber, jamaahOrderID, key) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/order/${orderNumber}/followup/${key}/jamaah-order/${jamaahOrderID}/send`);

const handleGetFollowupTemplate = (key) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/order/followup`, { params: { key } });

const handleUpdateFollowupTemplate = (key, payload) => 
  axios.put(`${BASE_URL_ENDPOINT}/admin/order/followup/${key}/update`, payload)

const linkingJamaahAccountService = (orderNumber, jamaahOrderID, payload) =>
  axios.put(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/jamaah/${jamaahOrderID}/chose-account`, payload)

const upadateChosePackageService = (orderNumber, payload) =>
  axios.put(`${BASE_URL_ENDPOINT_V2}/admin/order/${orderNumber}/change-chose-package`, payload)


export { 
  BookingUmrohAPI, 
  handleCancelBooking, 
  handleGetBookingList, 
  handleGetJamaahBookingList, 
  handleStoreBookingAPI,
  handleGetBookingDetail,
  handleGetDataJamaahAPI,
  handleGetDataManifestAPI,
  handleGetDataAdditionalsAPI,
  handleGetDataEquipmentsAPI,
  handleStoreDataJamaahAPI,
  handleAddAdditionalAPI,
  handleUpdateEquipmentsAPI,
  handleUpdateManifestAPI,
  handleUpdateJamaahOrderAPI,
  handleUpdateAdditionalByJamaahOrderAPI,
  handleDeleteAdditionalByJamaahOrderAPI,
  handleGetPaymentsAPI,
  handleStorePaymentAPI,
  handleUpdatePaymentAPI,
  handleUpdateDiscountAPI,
  handleGetAccountJamaahAPI,
  handleRegisterAccountJamaahAPI,
  handleCancelJamaahAPI,
  handleGenerateEBuktiBooking,
  handleSendFollowup,
  handleSendFollowupToJamaahOrder,
  handleGetFollowupTemplate,
  handleUpdateFollowupTemplate,
  linkingJamaahAccountService,
  upadateChosePackageService
};
