import React from "react";
import PropType from "prop-types";
import { TimePicker } from "antd";

import TimePickersStyle from "./TimePickers.Style";

import IconClockActive from "../../assets/icons/clock-active.svg";
import Close from "assets/icons/Close.svg";

const TimePickers = ({
  containerClass,
  customClass,
  label,
  format,
  disabled,
  onChange,
  onSelect,
  error,
  value,
  placeholder,
}) => {
  return (
    <>
      <TimePickersStyle className={`${containerClass}`}>
        {label !== "" && <div className="font-semibold mb-2 text-sm">{label}</div>}
        <TimePicker
          placeholder={placeholder}
          customClass={`custom-timepicker ${customClass}`}
          defaultValue={value}
          format={format}
          disabled={disabled}
          onChange={onChange}
          onSelect={onSelect}
          getPopupContainer={() => document.getElementById("area-timepicker")}
          suffixIcon={
            <div>
              <img className="w-fit h-fit" src={IconClockActive} alt="clock" />
            </div>
          }
        />
        <div id="area-timepicker" className="custom-timepicker-open"></div>
      </TimePickersStyle>
      {error && (
        <div className="flex flex-row items-center mt-1 gap-x-2">
          <img src={Close} alt="close" />
          <div className="text-red-500">{error}</div>
        </div>
      )}
    </>
  );
};

TimePickers.propTypes = {
  containerClass: PropType.string,
  customClass: PropType.string,
  label: PropType.string,
  format: PropType.string,
  disabled: PropType.bool,
  onChange: PropType.func,
  onSelect: PropType.func,
  value: PropType.string,
  placeholder: PropType.string,
};

TimePickers.defaultProps = {
  containerClass: "",
  customClass: "",
  label: "",
  format: "HH:mm",
  disabled: false,
  onChange: () => {},
  onSelect: () => {},
  value: null,
  placeholder: "",
};

export default TimePickers;
