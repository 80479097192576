// cabang Actions
// --------------------------------------------------------

import {
  activeNoWACabangService,
  checkLisensiWAService,
  checkQRWAStatus,
  createCabangService,
  createWaEmailApi,
  deleteIdCabangService,
  generateQRWAService,
  getAllCabangService,
  getDetailCabangService,
  getHistoryWAWAService,
  getInfoWAService,
  getRegionApi,
  logoutWAService,
} from "../../../utils/api/cabang";
import { handleError, handleErrorBE, handleSuccess } from "../errorGeneral";

import { detailById } from "stores/actions/general";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "cabang/SET_LOADING";
export const CLEAR_ERROR = "cabang/CLEAR_ERROR";
export const SET_ERROR = "cabang/SET_ERROR";
export const INIT_DATA = "cabang/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "cabang/SET_DOUBLE_SUBMIT";
export const SET_DATA_ALL_CABANG = "cabang/SET_DATA_ALL_CABANG";
export const SET_DETAIL_CABANG = "cabang/SET_DETAIL_CABANG";
export const SET_DELETE_ID_CABANG = "cabang/SET_DELETE_ID_CABANG";
export const SET_CREATE_CABANG = "cabang/SET_CREATE_CABANG";
export const SET_WA_EMAIL = "cabang/SET_WA_EMAIL";
export const SET_REGION = "cabang/SET_WA_EMAIL";
export const CHECK_QR_STATUS_SET_LOADING = "cabang/CHECK_QR_STATUS_SET_LOADING";
export const SET_QR_STATUS = "cabang/SET_QR_STATUS";
export const DETAIL_SET_LOADING = "cabang/DETAIL_SET_LOADING";

export const GENERATE_QR_SET_LOADING = "cabang/GENERATE_QR_SET_LOADING";

export const HISTORY_SET_LOADING = "cabang/HISTORY_SET_LOADING";

export const CHECK_LICENSE_SET_LOADING = "cabang/CHECK_LICENSE_SET_LOADING";

export const ENABLE_WA_BRANCH_LOADING = "cabang/ENABLE_WA_BRANCH_LOADING";

export const LOGOUT_WA_LOADING = "cabang/LOGOUT_WA_LOADING";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const detailSetLoading = (payload) => ({
  type: DETAIL_SET_LOADING,
  payload,
});

export const historySetLoading = (payload) => ({
  type: HISTORY_SET_LOADING,
  payload,
});

export const checkLicenseSetLoading = (payload) => ({
  type: CHECK_LICENSE_SET_LOADING,
  payload,
});

export const enableWABranchLoading = (payload) => ({
  type: ENABLE_WA_BRANCH_LOADING,
  payload,
});

export const setLogoutWALoading = (payload) => ({
  type: LOGOUT_WA_LOADING,
  payload,
});

export const generateQRSetLoading = (payload) => ({
  type: GENERATE_QR_SET_LOADING,
  payload,
});

export const checkQRWAStatusLoading = (payload) => ({
  type: CHECK_QR_STATUS_SET_LOADING,
  payload,
});

export const setQRWAStatus = (payload) => ({
  type: SET_QR_STATUS,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataAllCabang = (payload) => ({
  type: SET_DATA_ALL_CABANG,
  payload,
});

export const setDetailCabang = (payload) => ({
  type: SET_DETAIL_CABANG,
  payload,
});

export const setDeleteIdCabang = (payload) => ({
  type: SET_DELETE_ID_CABANG,
  payload,
});

export const setCreateCabang = (payload) => ({
  type: SET_CREATE_CABANG,
  payload,
});

export const setWaEmail = (payload) => ({
  type: SET_WA_EMAIL,
  payload,
});

export const setRegion = (payload) => ({
  type: SET_REGION,
  payload,
});

export const getAllCabang = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getAllCabangService(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setDataAllCabang(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailCabang = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getDetailCabangService(id);

    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setDetailCabang(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getBranchDetail = (payload) => async (dispatch) => {
  dispatch(detailSetLoading(true));

  dispatch(detailById({ detail: true, data: payload }));

  dispatch(
    getInfoKontakWA(
      { id_cabang: payload.id_cabang },
      (message) => {
        dispatch(detailById({ detail: true, data: payload, contact: message }));
        dispatch(detailSetLoading(false));
      },
      (error) => {
        dispatch(detailSetLoading(false));
      }
    )
  );
};

export const deleteIdCabang = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await deleteIdCabangService(id);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(handleSuccess(res.data));

      dispatch(setDeleteIdCabang(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const actCreateCabang =
  (payload, actionSucessCallBack) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await createCabangService(payload);
      dispatch(setLoading(false));
      if (res.status === 200) {
        dispatch(setCreateCabang(res.data));
        localStorage.setItem("id_cabang", res.data?.data?.id_cabang);
        actionSucessCallBack(res.data);
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const actCreateWaEmail =
  (payload, actionCallbackSucess) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await createWaEmailApi(payload);
      dispatch(setLoading(false));
      if (res.status === 200) {
        actionCallbackSucess(res.data);
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getRegion = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getRegionApi(payload);
    // console.log(res);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setRegion(res.data));
      // dispatch(handleSuccess(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
    // console.log("error");
  } finally {
    dispatch(setLoading(false));
  }
};

export const activeNoWACabang =
  (payload, actionCallbackSucess) => async (dispatch) => {
    try {
      dispatch(enableWABranchLoading(true));
      const res = await activeNoWACabangService(payload);
      dispatch(enableWABranchLoading(false));
      if (res.status === 200) {
        dispatch(handleSuccess(res));
        actionCallbackSucess(res);
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const checkingLisensiNoWA =
  (payload, actionCallbackSucess, actionCallBackError) => async (dispatch) => {
    try {
      dispatch(checkLicenseSetLoading(true));
      const res = await checkLisensiWAService(payload);
      dispatch(checkLicenseSetLoading(false));
      if (res.status === 200) {
        actionCallbackSucess(res.data);
      } else {
        actionCallBackError(res.data);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const generateQRwaWA =
  (payload, actionCallbackSucess) => async (dispatch) => {
    try {
      dispatch(generateQRSetLoading(true));
      const res = await generateQRWAService(payload);
      dispatch(generateQRSetLoading(false));
      if (res.status === 200) {
        actionCallbackSucess(res.data);
      } else {
        // dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getInfoKontakWA =
  (payload, actionCallbackSucess, actionCallBackError) => async (dispatch) => {
    try {
      const res = await getInfoWAService(payload);
      if (res.status === 200) {
        actionCallbackSucess(res.data);
      } else {
        actionCallBackError(res);
        if (res.status != 404) {
          dispatch(handleError(res));
        }
      }
    } catch (error) {
      actionCallBackError(error);
      dispatch(handleError(error));
    }
  };

export const getHistoryWA =
  (payload, actionCallbackSucess) => async (dispatch) => {
    try {
      dispatch(historySetLoading(true));
      const res = await getHistoryWAWAService(payload);
      dispatch(historySetLoading(false));
      if (res.status === 200) {
        setQRWAStatus(res.data.message);
        actionCallbackSucess(res.data);
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const logoutWA = (payload, actionCallbackSucess) => async (dispatch) => {
  try {
    dispatch(setLogoutWALoading(true));
    const res = await logoutWAService(payload);
    dispatch(setLogoutWALoading(false));
    if (res.status === 200) {
      dispatch(handleSuccess(res.data));
      actionCallbackSucess(res);
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const checkingQRWAStatus =
  (payload, actionCallbackSucess, actionCallBackError) => async (dispatch) => {
    try {
      // dispatch(checkQRWAStatusLoading(true));
      const res = await checkQRWAStatus(payload);
      if (res.status === 200) {
        actionCallbackSucess(res.data);
        // dispatch(checkQRWAStatusLoading(false));
      } else {
        actionCallBackError(res.data);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
