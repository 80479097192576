import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconSidebar from "../../../assets/images/logo-mosleme-travel.svg";
import IconFee from "../../../assets/icons/IconFee.svg";
import FotoProfile from "../../../assets/images/profile_new.png";
import IconMoon from "../../../assets/icons/Mode.svg";
import IconScring from "../../../assets/icons/brush.svg";
import IconNotif from "../../../assets/icons/notif_new.svg";

import {
  getList,
  getCountUnread,
} from "../../../stores/actions/notifications";


import AvatarIkhwan from"../../../assets/icons/ava-ikhwan.svg"
import NurRamadhanLogo from "../../../assets/images/nur-ramadhan-logo.png";

import { useDispatch, useSelector } from "react-redux"

import { IDRFormater, USDFormater, getLocalStorage } from "utils/helpers";

import { handleGetSaldoFee } from "stores/actions/fee";

import { handleGetExchangeRate } from "stores/actions/exchangeRateUSD";

import Avatar from "components/avatar";
import Popup from "components/popup";
import ExchangeRateUSDSetting from "./components/ExchangeRateUSDSetting";

const useStyles = makeStyles({
  header: {
    padding: "18px 24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff !important",
    background: "green",
    // margin: "16px 16px 0px",
    position: "fixed",
    top: "16px",
    zIndex: "999",
    left: "108px",
    right: "16px",
    borderRadius: "16px",
    boxShadow:
      "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
    "& .lefsidebar": {
      display: "flex",
      flexDirection: "row",
      "& .container": {
        marginLeft: "32px",
        "& .title": {
          color: " #000",
          fontFamily: "GeneralSansSemibold",
          fontWeight: "600",
          fontSize: "18px",
          "& .label": {
            fontSize: "18px",
            fontFamily: "GeneralSans",
            color:
              "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
            fontWeight: "400",
          },
        },
        "& .subtitle": {
          color: "#FFBFAB",
          fontFamily: "GeneralSansRegular",
        },
      },
    },
    "& .rightsidebar": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      width: "400px",
      "& .cardFee": {
        boxSizing: "border-box",
        width: "160px",
        height: "47px",
        borderRadius: "12px",
        backgroundColor: "rgba(46, 179, 67, 0.2)",
        padding: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        "& .columnContainer": {
          display: "flex",
          flexDirection: "column",
          "& .label": {
            fontFamily: "GeneralSansRegular",
            fontSize: "14px",
            fontWeight: "400",
            color: "#151515",
          },
          "& .saldo": {
            fontFamily: "GeneralSansBold",
            fontSize: "14px",
            fontWeight: "bolder",
            color: "#01F91A !important",
            lineHeight: "none !important",
          },
        },
        ".card-exchange-rate:hover": {
          backgroundColor: "red!important",
        },
        "& .icon": {},
      },
      "& .icon_img": {
        display: "flex",
        justifyContent: "end",
        gap: "32px",
        alignItems: "center",
        width: "182px",
        "& .notif": {
          width: "32px",
          height: "32px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          position: "relative",
          "& .round": {
            position: "absolute",
            width: "16px",
            height: "16px",
            borderRadius: "48px",
            background: "linear-gradient(180deg, #F5E97C 0%, #E2A246 100%)",
            right: "0px",
            top: "0px",
            color:
              "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
            fontFamily: "GeneralSans",
            fontWeight: "600",
            fontSize: "8px",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          },
        },
        "& .profile": {
          width: "38px",
          height: "38px",
          borderRadius: "20px",
          cursor: "pointer",
          position: "relative",
          "& .icon_active": {
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            background: "#A7C957",
            border: "1px solid #ffff",
            position: "absolute",
            left: "2px",
            bottom: "4px",
          },
        },
      },
    },
  },
});

const HeaderLayout = ({ handleClick, handleOpenNotification, handleOpenExchangeRateSetting }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useHistory();

  const auth = useSelector(({ auth }) => auth)
  const permissions = auth?.user?.permissions;

  // get count unread from reducers notifications
  const countUnread = useSelector(({ notifications }) => notifications.countUnread);

  const { saldoFee } = useSelector(({ fee }) => fee);
  const { exchangeRate } = useSelector(({ exchangeRateUSD }) => exchangeRateUSD);

  const userLogin = getLocalStorage("userLogin");
  const userLoginJsonParse = userLogin && JSON.parse(userLogin);

  const [openExchangeRateSetting, setOpenExchangeRateSetting] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      if (auth.user?.access_data === 'self_branch') {
        if(permissions?.includes('view_withdraw-fee')) {
          dispatch(handleGetSaldoFee());
        }
      }

      if (auth.user?.access_data === 'all_branches') {
        if(permissions?.includes('view_exchange-rate-usd')) {
          console.log("Hiikuuu")
          dispatch(handleGetExchangeRate());
        }
      }
    };

    // Panggil fungsi fetchData() saat komponen dimuat pertama kali
    fetchData();

    // Set interval untuk menjalankan fetchData() setiap 1 menit
    const intervalId = setInterval(fetchData, 1 * 60 * 1000);

    // Membersihkan interval saat komponen dilepas
    return () => clearInterval(intervalId);
  }, [auth, dispatch]);

  useEffect(() => {
    dispatch(getCountUnread());
    dispatch(getList());
  }, [])

  return (
    <>
      <Popup
        open={openExchangeRateSetting}
        handleClose={() => setOpenExchangeRateSetting(false)}
        title="Pengaturan Kurs"
        width="672px"
        containerClass=""
        children={<ExchangeRateUSDSetting />}
      />
      <div className={classes.header}>
        <div className="lefsidebar">
          <img src={NurRamadhanLogo} width="170px" alt="icon-sidebar" />
          <div className="container">
            <Typography className="title">
              <b>Assalamu'alaikum,</b>{" "}
              <label className="label">{auth?.user?.name}</label>👋
            </Typography>
            <Typography className="subtitle">{auth?.user?.nama_role}</Typography>
          </div>
        </div>
        <div className="rightsidebar flex !justify-end">
          {permissions?.includes('view_exchange-rate-usd') && (
            <>
              <div className="group mr-6 relative">
                <div 
                  onClick={() => setOpenExchangeRateSetting(true)}
                  className="cursor-pointer bg-[#F2F2F2] rounded-xl px-4 py-1">
                  <div className="font-[10px]">
                    KURS
                  </div>
                  <div className="font-[10px] green-linear-text">
                    {USDFormater(1)} / {IDRFormater(exchangeRate?.rate_sell)}
                  </div>
                </div>
                <div 
                  className="echange-view hidden group-hover:flex flex-col gap-4 absolute w-[200px] left-[-25%] top-[62px] p-4 bg-white shadow-hard rounded-[24px] 
                    before:content-[''] before:absolute before:top-[-10px] before:left-[50%] before:transform before:-translate-x-1/2 
                    before:border-l-[10px] before:border-r-[10px] before:border-b-[10px] before:border-l-transparent before:border-r-transparent 
                    before:border-b-white"
                  >
                  <div className="bg-gray-5 px-4 py-2 rounded-xl flex text-sm justify-between items-center">
                    <div>USD</div>
                    <div className="font-semibold">
                      {USDFormater(1)}
                    </div>
                  </div>
                  <div className="bg-gray-5 px-4 py-2 rounded-xl flex text-sm justify-between items-center">
                    <div>IDR Jual</div>
                    <div className="font-semibold">
                      {IDRFormater(exchangeRate?.rate_sell)}
                    </div>
                  </div>
                  <div className="bg-gray-5 px-4 py-2 rounded-xl flex text-sm justify-between items-center">
                    <div>IDR Beli</div>
                    <div className="font-semibold">
                      {IDRFormater(exchangeRate?.rate_buy)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {auth?.user?.access_data === 'self_branch' && (
            <>
              {permissions?.includes('view_withdraw-fee') && (
                <div
                  className="cardFee cursor-pointer mr-6"
                  onClick={() => router.push("/fee-penarikan")}
                >
                  <div className="columnContainer">
                    <div className="label">Total Fee</div>
                    <div className="saldo">{ IDRFormater(saldoFee) }</div>
                  </div>
                  <div className="icon">
                    <img src={IconFee} alt="icon_fee" />
                  </div>
                </div>
              )}
            </>
          )}
          <div className="icon_img">
            {/* <img src={IconMoon} alt="icon_moon" width={20} height={20} />
            <img src={IconScring} alt="icon_scring" width={20} height={20} /> */}
            <div className="notif cursor-pointer" onClick={handleOpenNotification}>
              {countUnread > 0 && (
                <>
                  <div className="round">
                    {countUnread > 99 ? "99+" : countUnread}
                  </div>
                </>
              )}
              <img src={IconNotif} alt="icon_notif" width={20} height={20} />
            </div>

            <div className="profile" onClick={handleClick}>
              <Avatar value={auth?.user} />

              <div className="icon_active"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(HeaderLayout);
