import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import SelectStyle from "./Select.Style";



import ArrowDown from "assets/icons/Down-Arrow.svg";
import Close from "assets/icons/Close.svg";
import Check from "assets/icons/Check.svg";
import Secondary from "./custom-type/Secondary";

const AntdSelect = ({
  containerClass,
  customClass,
  id,
  label,
  placeholder,
  icon,
  iconPosition,
  options,
  children,
  disabled,
  dropdownRender,
  success,
  error,
  type,
  onChange,
  defaultValue,
  onSearch,
  ...props
}) => {
  const { Option } = Select
  return (
    <SelectStyle className={`${containerClass}`}>
      {label !== "" && (
        <div className={`select-label ${disabled && "select-label--disabled"}`}>
          {label}
        </div>
      )}
      <div className="flex flex-row relative">
        {icon && iconPosition === "left" && (
          <img src={icon} className="ic-left" alt="icon-left" />
        )}
          <Select
            labelInValue
            id={id}
            suffixIcon={
              <img src={ArrowDown} className="ic-right" alt="ic-right" />
            }
            className={`custom-select ${
              icon !== "" && "select-padding--left"
            } ${customClass}`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            options={type ? undefined : options}
            onSearch={onSearch}
            defaultValue={defaultValue}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            getPopupContainer={() => document.getElementById(`area-select`)}
            {...props}
          >
            
            {type ? options.map((item) => (
              <Option value={item.value} label={item.label} className="select-custom-option-secondary">
                <Secondary option={item} />
              </Option>
            ))
            : children
            }
          </Select>
      </div>
      {success !== "" && (
        <div className="flex flex-row items-center">
          <img src={Check} alt="check" />
          <div className="select-success-msg">{success}</div>
        </div>
      )}
      {error !== "" && (
        <div className="flex flex-row items-center">
          <img src={Close} alt="close" />
          <div className="select-error-msg">{error}</div>
        </div>
      )}
      <div id="area-select" className="area-select"></div>
    </SelectStyle>
  );
};

AntdSelect.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
  iconPosition: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  success: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  defaultValue: PropTypes.string
};

AntdSelect.defaultProps = {
  containerClass: "",
  customClass: "",
  id: "",
  label: "",
  placeholder: "placeholder",
  icon: "",
  iconPosition: "left",
  options: [],
  disabled: false,
  success: "",
  error: "",
  onChange: () => {},
  onSearch: () => {},
  type: null,
  defaultValue: ''
};

export default React.memo(AntdSelect);