


import IconBack from "assets/icons/arrow-left-dark-icon.svg";

import MoslemeTable from "components/Table";

import { getLogActivitysService } from "utils/api/log-activities";

import { useEffect, useState } from "react";
import moment from "moment";
import DateRangePicker from "components/datepicker/DateRangePicker";
import Inputs from "components/form/inputs";
import useDebounce from "utils/helpers/useDebounce";

import IconSearch from "assets/icons/search-normal.svg";

const LogActivities = ({
    handleBack,
    masterData
}) => {

  const [isLoading, setIsLoading] = useState(false);
  
  const [keyword, setKeyword] = useState('')
  const searchDebounce = useDebounce(keyword, 1000)

  const [activities, setActivities] = useState([]);
  const [activityTotal, setActivityTotal] = useState(0);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterDateRange, setFilterDateRange] = useState([])

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangePageSize = (size) => {
    setCurrentPage(1);
    setPageSize(size);
  };
  
  const pagination = {
    showSizeChanger: true,
    total: activityTotal,
    current: currentPage,
    pageSize: pageSize,
    defaultPageSize: pageSize,
    pageSizeOptions: ["10", "20", "50", "100"],
  };
  
  const columns = [
    {
      title: "Nama",
      dataIndex: "nama",
      width: "200px"
    },
    {
      title: "Aksi",
      dataIndex: "action",
    },
    {
      title: "Deskripsi",
      dataIndex: "deskripsi",
      render: (record) => {
          const parse = {__html: record}

          return (
            <div dangerouslySetInnerHTML={parse}></div>
          )
      }
    },
    {
      title: "Waktu",
      dataIndex: "created_at",
      render: (record) => (
        moment(record).format("dddd, DD MMMM YYYY HH:mm") + " WIB"
      )
    },
  ];

  const handleGetActivities = async () => {
    setIsLoading(true);

    const payload = {
      page: currentPage,
      per_page: pageSize,
      master_data: masterData,
      filter_date_range: filterDateRange,
      keyword: keyword
    };

    const result = await getLogActivitysService(payload);
    if (result?.data) {
      setActivities(result?.data?.data);
      setActivityTotal(result?.data?.total);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetActivities();
  }, [currentPage, pageSize, masterData, filterDateRange, searchDebounce]);

  return (
      <>
        <div className="card-table-header border-b">
          <div className="flex flex-row justify-between items-center">
            <div className="flex gap-4 items-center">
              <img
                  src={IconBack}
                  alt="back button"
                  className="w-[16px] h-[16px] cursor-pointer"
                  onClick={() => handleBack()}
                  // onClick={() => router.push(`/paket/${handleParamsPackageType(store?.data?.tipe_paket)}`)}
              />
              <h1 className="font-semibold text-xl">Log Activity</h1>
            </div>
            <div className="flex gap-4 items-center">
                <Inputs
                  id="search"
                  placeholder="Cari..."
                  customClass="!w-[245px]"
                  iconPosition="left"
                  value={keyword}
                  icon={IconSearch}
                  onChange={(e) => setKeyword(e.target.value)}
                  />
                <DateRangePicker
                  value={filterDateRange}
                  placeholder="Dari"
                  placeholder2={"Sampai"}
                  onChange={(date) => {
                    setFilterDateRange(date)
                  }}
                />
            </div>
          </div>
        </div>
        <div>
          <MoslemeTable
                loading={isLoading}
                // expandable={isExpand && expandable}
                columns={columns}
                data={activities}
                withSelection={true}
                selectionType={'checkbox'}
                pagination={pagination}
                customPaginationClass="pr-4"
                // customClass="mt-4"
                onPageChange={(page) => handleChangePage(page)}
                onSizeChange={(currentpage, pageSize) => {
                  handleChangePage(currentpage);
                  handleChangePageSize(pageSize);
                }}
              />
        </div>
      </>
  )
}

export default LogActivities