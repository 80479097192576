import styled from "styled-components";

const TabContainer = styled.div`
  .ant-tabs .ant-tabs-tab:hover {
    color: #bdbdbd;
  }

  .ant-tabs-nav-wrap {
    padding: 0px;
    padding-top: 0px;
    width: 100%;
  }

  .ant-tabs .ant-tabs-nav {
    margin: 0px 0px 0px 0px
    padding: 0px 1rem;
    width: 100%;
    // border-bottom-width: 1px;
  }

  .ant-tabs-nav {
    width: 100%;
    margin-bottom: 0px;
    // border-bottom-width: 1px;
  }

  .ant-tabs-tab-btn {
    color: #bdbdbd;
    &:hover {
      color: rgba(0, 0, 0, 0.88) !important;
      background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 500;
      text-fill-color: transparent;
    }
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: transparent !important;
    background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 500;
    text-fill-color: transparent;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
    pointer-events: none;
  }

  .ant-tabs-tab-active {
  }
`;

export default TabContainer;
