import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Buttons from "components/buttons";
import MoslemeTable from "components/Table";

import IconCSV from "assets/icons/CSV.svg";

import { exportReportStartingService, getListReportStartingService } from "utils/api/paket/laporan";
import { handleError } from "stores/actions/errorGeneral";
import { IDRFormater, formattorbase64, handleName } from "utils/helpers";
import Avatar from "components/avatar";
import Badge from "components/badge";

const PaketLaporanStarting = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const [startingReports, setStartingReports] = useState([])

  // Expaneble
  const [isExpand, setIsExpand] = useState(true);
  const [tableExpand, setTableExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  const [orderSelectedID, setOrderSelectedID] = useState(null);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const handleGetReportStartings = async () => {
    setIsLoading(true);
    const payload = {
      "sort_column" : "id",
      "sort_order" : "asc",
      "per_page" : size,
      "page_number" : currentpage,
      'data': 'starting'
    }
    const response = await getListReportStartingService(
      params.id,
      payload
    )
    setIsLoading(false);

    if (response.status === 200) {
      setStartingReports(response.data.data)
      setTotal(response.data.total_data)
    } else {
      dispatch(handleError(response.data));
    }
  }

  const handleDownloadCSV = async () => {
    setIsLoadingExport(true);
    const response = await exportReportStartingService(params.id);
    setIsLoadingExport(false);
    if (response.status === 200) {
      const { base64, type, file_name } = response.data.data;
      const insertPrefix = formattorbase64(type, base64);
      var a = document.createElement("a"); //Create <a>
      a.href = `${insertPrefix}`; //Image Base64 Goes here
      a.download = `${file_name}`; //File name Here
      a.click();
    } else {
      dispatch(handleError(response.data));
    }
  };

  const columns = [
    {
      title: "Foto",
      width: "40px",
      render: (data) => <>
        <div>
          <Avatar value={data?.jamaah} />
        </div>
      </>,
    },
    {
      title: "Nama Sesuai KTP",
      width: "250px",
      render: (data) => <>
        <div>
          {handleName(data?.jamaah?.title, data?.jamaah?.name)}
        </div>
      </>,
    },
    {
      title: "Nama Sesuai Passport",
      render: (data) => <>
        <div>
          {data?.jamaah?.passport_name}
        </div>
      </>,
    },
    {
      title: "Starting",
      width: "200px",
      render: (data) => <>
        <div>
          <Badge
            label={data?.starting?.name}
            backgroundColor={data?.starting?.bg_color}
            color={data?.starting?.text_color}
            />
        </div>
      </>,
    },
    {
      title: "Cabang",
      width: "200px",
      render: (data) => <>
        <div>
          <Badge
            label={data?.branch?.name}
            backgroundColor={data?.branch?.bg_color}
            color={data?.branch?.text_color}
            />
        </div>
      </>,
    },
  ];


  useEffect(() => {
    handleGetReportStartings()
  }, [dispatch, currentpage, size])

  return (
    <>
      <div className="bg-white border-[1px] border-[#4048521a] rounded-bl-lg rounded-br-lg">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="text-[20px] font-semibold">Laporan Starting</div>
          <Buttons
            loading={isLoadingExport}
            text="Export CSV"
            customClass="btn-outline btn-small"
            icon={IconCSV}
            onClick={handleDownloadCSV}
          />
        </div>
        <div className="">
          <MoslemeTable
            loading={isLoading}
            columns={columns}
            data={startingReports}
            withSelection={withSelection}
            selectionType={selectionType}
            rowKey={(record) => record.order_id}
            pagination={pagination}
            onPageChange={(page) => setCurrentpage(page)}
            onSizeChange={(currentpage, pageSize) =>
              onSizeChange(currentpage, pageSize)
            }
          />
        </div>
      </div>
      <div id="area-modal"></div>
    </>
  );
};

export default React.memo(PaketLaporanStarting);
