import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";

import {
    getAllDataAkomodasi
  } from "stores/actions/datamaster";

import Buttons from "components/buttons";
import AlertPopup from "components/popup/alert";

import IconTrash from "assets/icons/trash-maroon.svg";
import IconArrowSquareDown from "assets/icons/arrow-square-down-2.svg";

import Inputs from "components/form/inputs";
import AntdSelect from "components/form/antdSelect";
import InputsCurrency from "components/form/inputsCurrency";
import moment from "moment/moment";

const MaskapaiPriceUpdate = ({
    akomodasiToursData,
    countryTour,
    handleUpdateAkomodasi
}) => {

    const dispatch = useDispatch()
    const masterDataStore = useSelector(({ datamaster }) => datamaster);

    const [actionIndex, setActionIndex] = useState(null)
    const [removeConfirm, setRemoveConfirm] = useState({ show: false, index: null })

    const [isGetAllLoading, setGetAllLoading] = useState(false)
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isRemoveLoading, setIsRemoveLoading] = useState(false)
    const [isGetPriceLoading, setIsGetPriceLoading] = useState(false)

    const [months, setMonths] = useState([])
    const [years, setYears] = useState([])

    const [masterAkomodasi, setMasterAkomodasi] = useState([])

    const [akomodasiTours, setAkomodasiTours] = useState([])

    useEffect(() => {
        setAkomodasiTours(akomodasiToursData)
    }, [akomodasiToursData])

    useEffect(() => {

        const allAkomodasiIDSelected = akomodasiTours.map(item => item.data.akomodasi_id)
        let masterAkomodasiTemp = masterAkomodasi.filter(item => !allAkomodasiIDSelected.includes(item.id))

        setMasterAkomodasi(masterAkomodasiTemp)
    }, [akomodasiTours])

    useEffect(() => {
        setMonths([
            { value: "01", label: "Januari" },
            { value: "02", label: "Februari" },
            { value: "03", label: "Maret" },
            { value: "04", label: "April" },
            { value: "05", label: "Mei" },
            { value: "06", label: "Juni" },
            { value: "07", label: "Juli" },
            { value: "08", label: "Agustus" },
            { value: "09", label: "September" },
            { value: "10", label: "Oktober" },
            { value: "11", label: "November" },
            { value: "12", label: "Desember" },
        ]);

        setYears(handleSetYears())
    }, [])

    useEffect(() => {
        if (masterDataStore?.dataMaskapai?.data?.length > 0) {
            let masterAkomodasiTemp = [];
            masterDataStore?.dataAkomodasi?.data?.map((val) => {
              masterAkomodasiTemp.push({
                id: val.id_akomodasi,
                img: val.logo,
                star: val.bintang,
                address: val.alamat_lengkap,
                value: val.id_akomodasi,
                label: val.name,
                key: val.id_akomodasi
              });
            });
            setMasterAkomodasi(masterAkomodasiTemp);
        }
    
      }, [
        masterDataStore.dataAkomodasi,
        masterDataStore.dataAkomodasi.length,
    ]);

    const onHandleSaveAkomodasi = async (index) => {
        setActionIndex(index)

        try {
            setIsSaveLoading(true)

            let akomodasiToursTemp = [...akomodasiTours]
            const akomodasi = akomodasiToursTemp[index];

            const res = await PriceUpdateAPI.countryToursAkomodasiSave(akomodasi.data)

            if(res.status === 200) {
                dispatch(handleSuccess(res))

                akomodasiToursTemp[index].props.active = true
                akomodasiToursTemp[index].props.updated = false

                handleUpdateAkomodasi(akomodasiToursTemp)

            } else {
                dispatch(handleError(res))
            }

            setIsSaveLoading(false)

        } catch(error) {
            setIsSaveLoading(false)
            dispatch(handleError(error))
        }
        
    };
    const onHandleRemove = async (index) => {
        try {

            setIsRemoveLoading(true)

            const akomodasiToursTemp = [...akomodasiTours]
            const akomodasi = akomodasiToursTemp[index];

            const res = await PriceUpdateAPI.countryToursAkomodasiRemove(akomodasi.data)

            if(res.status === 200) {
                dispatch(handleSuccess(res))
                akomodasiToursTemp.splice(index, 1)

                handleUpdateAkomodasi(akomodasiToursTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsRemoveLoading(false)
            setRemoveConfirm({ show: false, index: 0 })
        } catch(error) {
            setIsRemoveLoading(false)
            dispatch(handleError(error))
        }
    }
    const onHandleGetPrice = async (index) => {
        setActionIndex(index)

        try {
            setIsGetPriceLoading(true)

            let akomodasiToursTemp = [...akomodasiTours]
            const akomodasi = akomodasiToursTemp[index];

            const res = await PriceUpdateAPI.countryToursAkomodasiGetPrice(akomodasi.data)

            if(res.status === 200) {
                const price = res.data.data

                akomodasiToursTemp[index].data.price = price
                handleUpdateAkomodasi(akomodasiToursTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsGetPriceLoading(false)
        } catch (error) {
            setIsGetPriceLoading(false)
            dispatch(handleError(error))
        }
    }

    const handleSetYears = () => {
        const yearNow = new Date().getFullYear();

        // Membuat daftar tahun
        const yearList = [];
        
        for (let i = 0; i <= 20; i++) {
            const yearSelected = yearNow + i;
            yearList.push({ value: String(yearSelected), label: yearSelected });
        }

        yearList.sort((a, b) => a - b);

        return yearList;
    };

    const handleShowPrice = (index) => {
        let akomodasiToursTemp = [...akomodasiTours]

        akomodasiToursTemp[index].props.showPrice = !akomodasiToursTemp[index].props.showPrice;

        handleUpdateAkomodasi(akomodasiToursTemp);
    }
    const handleAddAkomodasi = () => {
        const newAkomodasi = {
          props: {
            active: false,
            showPrice: false,
            updated: false,
          },
          data: {
            paket_kalkulator_tour_id: countryTour?.data?.id,
            akomodasi_id: null,
            akomodasi_name: "",
            month: moment().format("MM"),
            year: moment().format("YYYY"),
            price: 0
          },
        }
    
        let akomodasiToursTemp = [...akomodasiTours]
        akomodasiToursTemp.push(newAkomodasi)
    
        handleUpdateAkomodasi(akomodasiToursTemp)
    }
    const handleCancelAddAkomodasi = (index) => {
        let akomodasiToursTemp = [...akomodasiTours];
        
        akomodasiToursTemp.splice(index, 1);
    
        handleUpdateAkomodasi(akomodasiToursTemp)
    }

    const akomodasiToursActive = akomodasiTours.filter(function (maskapai) {
        return maskapai.props.active === true;
    });
    
    const akomodasiToursDeactive = akomodasiTours.filter(function (maskapai) {
        return maskapai.props.active === false;
    });

    return (
        <>
            {countryTour.props.active ? (

                <>
                    <AlertPopup
                        open={removeConfirm.show}
                        handleClose={() => setRemoveConfirm({ show: false, index: 0 })}
                        handleContinue={() => onHandleRemove(removeConfirm.index)}
                        loading={isRemoveLoading}
                        subtitle="Apakah anda yakin menghapus maskapai ini?"
                    />

                    <div className="flex flex-col gap-4">
                        {akomodasiTours.map((akomodasi, index) => {
                            return (
                                <div
                                    className="border border-[#E0E0E0] p-4 rounded-2xl"
                                    key={index}
                                    >
                                        {/* <!----------- HEADER -----------> */}
                                        <div className="flex justify-between items-center">
                                            <div className="font-bold mb-4">
                                                {!akomodasi.props.active && "Tambah "}Hotel {countryTour?.data?.name}{" "}
                                                {index + 1}
                                            </div>
                                            <div className="flex justify-end gap-4 items-center">
                                                {!akomodasi.props.active ? (
                                                    <>
                                                        <Buttons
                                                            text="Batal"
                                                            onClick={() => handleCancelAddAkomodasi(index)}
                                                            customClass="btn-primary btn-outline btn-small"
                                                            />
                                                        <Buttons
                                                            text="Simpan"
                                                            loading={isSaveLoading ? actionIndex === index : false}
                                                            onClick={() => onHandleSaveAkomodasi(index)}
                                                            customClass="btn-primary btn-small"
                                                        />
                                                    </>
                                                ): null}
                                                {akomodasi.props.active ? (
                                                    <>
                                                        {akomodasiToursActive.length > 1 && (
                                                            <i
                                                                className="!cursor-pointer"
                                                                onClick={() =>
                                                                    setRemoveConfirm({ show: true, index })
                                                                }
                                                                >
                                                                <img
                                                                    src={IconTrash}
                                                                    className={`w-6 h-6`}
                                                                    />
                                                            </i>
                                                        )}
                                                        <Buttons
                                                            text="Simpan"
                                                            loading={isSaveLoading ? actionIndex === index : false}
                                                            onClick={() => {
                                                                onHandleSaveAkomodasi(index);
                                                            }}
                                                            disabled={!akomodasi.props.updated}
                                                            customClass="btn-primary btn-small"
                                                        />
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>

                                        {/* <!----------- CONTENT ----------------> */}
                                        <div className="flex gap-4 items-center mb-4">
                                            <div className="w-[83%]">
                                                {akomodasi.props.active ? (
                                                    <>
                                                        <Inputs
                                                            label="Hotel"
                                                            readonly
                                                            value={akomodasi.data.akomodasi_name}
                                                            disabled={isGetPriceLoading ? actionIndex === index : false}
                                                        />
                                                    </>
                                                ): null}
                                                {!akomodasi.props.active ? (
                                                    <AntdSelect
                                                        showSearch={true}
                                                        label="Hotel"
                                                        placeholder="Pilih akomodasi"
                                                        type="secondary"
                                                        options={masterAkomodasi}
                                                        onChange={(item) => {
                                                            let akomodasiToursTemp = [...akomodasiTours]
                        
                                                            akomodasiToursTemp[index].data.akomodasi_id = item.value
                                                            akomodasiToursTemp[index].data.akomodasi_name = item.label.props.option.label
                            
                                                            handleUpdateAkomodasi(akomodasiToursTemp)
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                            <div className="w-[83%]">
                                                <AntdSelect
                                                    label="Bulan"
                                                    placeholder="Pilih bulan"
                                                    options={months}
                                                    defaultValue={akomodasi.data.month}
                                                    onChange={(item) => {
                                                        let akomodasiToursTemp = [...akomodasiTours]

                                                        akomodasiToursTemp[index].data.month = item.value

                                                        handleUpdateAkomodasi(akomodasiToursTemp)

                                                        onHandleGetPrice(index)

                                                    }}
                                                    disabled={isGetPriceLoading ? actionIndex === index : false || akomodasi.props.updated}
                                                />
                                            </div>
                                            <div className="w-[83%]">
                                                <AntdSelect
                                                    label="Tahun"
                                                    placeholder="Pilih tahun"
                                                    options={years}
                                                    defaultValue={akomodasi.data.year}
                                                    onChange={(item) => {
                                                        let akomodasiToursTemp = [...akomodasiTours]

                                                        akomodasiToursTemp[index].data.year = item.value

                                                        handleUpdateAkomodasi(akomodasiToursTemp)

                                                        onHandleGetPrice(index)

                                                    }}
                                                    disabled={isGetPriceLoading ? actionIndex === index : false || akomodasi.props.updated}
                                                    />
                                            </div>
                                            <div className="w-[10%] relative">
                                                <div className="cursor-pointer absolute bottom--4">
                                                <img
                                                    onClick={() => {
                                                        handleShowPrice(index);
                                                    }}
                                                    src={IconArrowSquareDown}
                                                    className={`w-8 h-8 ${
                                                        akomodasi.props.showPrice && `rotate-180`
                                                    }`}
                                                />
                                                </div>
                                            </div>

                                        </div>
                                        <div
                                            className={`transition-all duration-500 ${
                                                akomodasi.props.showPrice
                                                ? `block`
                                                : `hidden`
                                            }`}
                                            >

                                            <InputsCurrency
                                                label="Harga"
                                                value={akomodasi.data.price}
                                                placeholder="0"
                                                // disabled={updateHargaStore.isGetPriceLoading ? akomodasiActionIndex === index : false}
                                                onChange={(val) => {
                                                    const akomodasiToursTemp = [...akomodasiTours];

                                                    akomodasiToursTemp[index].props.updated = true;
                                                    akomodasiToursTemp[index].data.price = val;

                                                    handleUpdateAkomodasi(akomodasiToursTemp)
                                                }}
                                            />
                                        </div>
                                </div>
                            )
                        })}
                    </div>
                    
                    <Buttons
                        text={`+ Tambah Hotel ${countryTour?.data?.name}`}
                        customClass="btn-outline btn-medium mt-4 !py-4"
                        disabled={akomodasiToursDeactive.length}
                        onClick={() => handleAddAkomodasi()}
                    />
                </>  

            ) : null}

        </>
    )

}

export default MaskapaiPriceUpdate