import axios from "axios";
import { BASE_URL_ENDPOINT, BASE_URL_ENDPOINT_V2 } from "../../../constant/constanta";

// get paket list
export const getAllPaketService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket`, payload);

export const getPaketDetailGeneralInfo = (id) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/paket/${id}`);

// itinenary
export const getListItinenaryApi = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/get-tipe-kamar-itinerary`,
    payload
  );

export const deleteIdItinenaryApi = (id, payload) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/itinerary/${id}`, payload);

export const detailItinenaryApi = (id, payload) =>
  axios.delete(
    `${BASE_URL_ENDPOINT}/admin/paket/tipe-kamar-itinerary/${id}`,
    payload
  );

export const createOrUpdateItinenaryApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-itinerary`, payload);

export const deleteGaleriItenaryApi = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/itinerary/gallery/${id}`);

export const duplicateItenaryApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/itinerary/duplicate`, payload);

// paket transportasi
export const getListPaketTransportasiApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/get-transportasi`, payload);

export const deleteIdTransportasiApi = (id, payload) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/transportasi/${id}`, payload);

// master tranportasi
export const getListTransportasiApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-transportasi`, payload);

// master tranportasi
export const storeTransportasiApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-transportasi`, payload);

// syarat ketentuan
const updateSKTApi = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/admin/paket/save-syarat-ketentuan-tgl`,
    payload
  );
const saveOrUpdateSKTApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-syarat-ketentuan`, payload);

const deleteSKTApi = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/syarat-ketentuan/${id}`);

export const syaratKetentuanApi = {
  main: updateSKTApi,
  saveOrUpdate: saveOrUpdateSKTApi,
  delete: deleteSKTApi,
};

// delete paket list
export const deleteAllPaketService = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/${id}`);

// insert paket manasik
export const savePaketManasikService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-manasik`, payload);

// insert paket manasik
export const getListManasikService = (id) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/paket/count-manasik-cabang/${id}`);

// get list manasik cabang
export const getManasikCabangService = (payload) =>
  axios.get(
    `${BASE_URL_ENDPOINT}/admin/paket/manasik/${payload.id}?cabang_id=${payload.cabang_id}`
  );

// delete manasik cabang 1 item
export const deleteManasikCabangService = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/manasik/${id}`);

export const sendManasikInvitationAPI = (packageID, manasikID) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/${packageID}/manasik/${manasikID}/send-invitation`)

export const changeStatusPackage = (payload) => 
  axios.put(`${BASE_URL_ENDPOINT}/admin/paket/set-draft/`, payload)


export const getPackagePublishList = (params) => {
  return axios.get(`${BASE_URL_ENDPOINT_V2}/admin/packages/publish`, { params });
}

export const getRoomPrices = (packageID) => {
  return axios.get(`${BASE_URL_ENDPOINT_V2}/admin/package/${packageID}/type-room/prices`);
}

export const duplicatePackage = (packageID) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/${packageID}/duplicate`)

export const getPublishSettingService = (packageID) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/paket/${packageID}/publish-settings`)

export const updatePublishSettingService = (packageID, payload) =>
  axios.put(`${BASE_URL_ENDPOINT}/admin/paket/${packageID}/publish-settings/update`, payload)