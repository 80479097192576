import {
  START_FETCHING_MEMBER_AGEN,
  SUCCESS_FETCHING_MEMBER_AGEN,
  ERROR_FETCHING_MEMBER_AGEN,
  SET_PAGE_AGEN,
  SET_SIZE_AGEN,
  SET_KEYWORD_AGEN,
  SET_TOTAL_AGEN,
  SET_ID_ROLE_AGEN,
  SET_ID_AGEN,
} from "../actionTypes";
import { AgenMemberAPI } from "../../../../utils/api/member";
import debounce from "debounce-promise";
import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
let debounceGetAgen = debounce(AgenMemberAPI.getListPage, 1500);

const startFetchingPMemberAgen = () => ({
  type: START_FETCHING_MEMBER_AGEN,
});

const errorFetchingPMemberAgen = () => ({
  type: ERROR_FETCHING_MEMBER_AGEN,
});

const successFetchingPMemberAgen = (data) => ({
  type: SUCCESS_FETCHING_MEMBER_AGEN,
  data,
});

const setPage = (page) => ({
  type: SET_PAGE_AGEN,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_AGEN,
  size,
});

const setTotal = (total) => ({
  type: SET_TOTAL_AGEN,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_AGEN,
  keyword,
});

const setRoleId = (id_role) => ({
  type: SET_ID_ROLE_AGEN,
  id_role,
});

const setAgenId = (id) => ({
  type: SET_ID_AGEN,
  id_agen: id,
});

const fetchDataAgen = (params) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingPMemberAgen());
    try {
      let per_page = getState().agen.per_page;
      let page_number = getState().agen.page_number;
      let sort_column = getState().agen.sort_column;
      let sort_order = getState().agen.sort_order;
      let keyword = getState().agen.keyword;
      let id_role = getState().agen.id_role;
      let payload;
      if (params) {
        payload = params;
      } else {
        payload = {
          per_page,
          page_number,
          sort_column,
          sort_order,
          keyword,
        };
      }

      if (id_role !== "0") {
        payload.id_role = id_role;
      }
      let res = await debounceGetAgen(payload);
      if (res.data.code === 401) {
        window.location.href = "/example";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingPMemberAgen(data));
      dispatch(setTotal(res.data.total_data));
      dispatch(setPage(res.data.page));
    } catch (error) {
      console.log("error: ", error.response);
      dispatch(errorFetchingPMemberAgen());
    }
  };
};

const createAgen = (payload, action, callbackError) => {
  return async (dispatch) => {
    try {
      let res = await AgenMemberAPI.postData(payload);

      if (res.status === 200) {
        if(payload.id) {
          action("Berhasil mengupdate agen");
        } else {
          action("Berhasil menambahkan agen");
        }
      } else {
        callbackError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const deleteAgen = (id, action) => {
  return async (dispatch) => {
    dispatch(startFetchingPMemberAgen());
    try {
      let res = await AgenMemberAPI.deleteData(id);
      if (res.status === 200) {
        action(res.data);
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const AgenAction = {
  fetchData: fetchDataAgen,
  createData: createAgen,
  deleteData: deleteAgen,
  setPage: setPage,
  setSize: setSize,
  setKeyword: setKeyword,
  setRoleId: setRoleId,
  setAgenId: setAgenId,
};

export default AgenAction;
