import PropTypes from "prop-types";
import IconRating from 'assets/icons/Rating.svg'
import IconRatingLight from 'assets/icons/star-light.svg'

const Secondary = ({
    option
}) => {
    return (
        <>
            <div className="select-custom-secondary flex items-center gap-4">
                <img 
                    src={option.img}
                    className="w-7 h-7 rounded-full"
                    />
                <div className="flex justify-between flex-col gap-2 content">
                    <div className="text-sm font-bold label">
                        {option.label}
                    </div>
                    <div className={`text-gray-3 text-sm desc ${option.star && 'flex'}`}>
                        {!option.star ? option.desc
                            : Array.from(Array(option.star), () => (
                                <img src={IconRatingLight} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

Secondary.protoTypes  = {
    option: PropTypes.object
}

Secondary.defaultProp = {
    option: {}
}

export default Secondary