import React from "react";
import PropTypes from "prop-types";
import { Modal, Fade, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Buttons from "../../buttons";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "436px",
    borderRadius: "16px",
    padding: "24px",
    boxShadow:
      "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
    background: "#fff",
    backgroundColor: "#fff",
    "& .title": {
      fontFamily: "GeneralSansRegular",
      fontSize: "20px",
      fontWeight: "600",
      color: "#101010",
      textAlign: "center",
    },
    "& .subtitle": {
      fontFamily: "GeneralSansRegular",
      fontSize: "16px",
      color: "#4F4F4F",
      marginTop: "16px",
      marginBottom: "24px",
      textAlign: "center",
    },
    "& .container_button": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
}));

const AlertPopup = ({
  open,
  handleClose,
  handleContinue,
  title,
  subtitle,
  loading,
  disabled,
  text_cancel,
  text_accept,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
            <div className="container_button">
              <Buttons
                text={text_cancel}
                customClass="btn-secondary btn-medium mr-[16px]"
                onClick={handleClose}
              />
              <Buttons
                text={text_accept}
                customClass="btn-primary btn-medium "
                onClick={handleContinue}
                loading={loading}
                disabled={loading}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

AlertPopup.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  text_accept: PropTypes.string.isRequired,
  text_cancel: PropTypes.string.isRequired,
};
AlertPopup.defaultProps = {
  title: "Apakah anda yakin?",
  subtitle: "Apakah kamu yakin ingin menghapus?",
  open: false,
  handleClose: () => {},
  handleContinue: () => {},
  loading: false,
  text_cancel: "Batal",
  text_accept: "Hapus",
};

export default React.memo(AlertPopup);
