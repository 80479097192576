import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import UploadArea from "components/Uploadarea"
import Buttons from "components/buttons"
import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { handleUpdateManifestAPI } from 'utils/api/booking'
import { handleError, handleSuccess } from 'stores/actions/errorGeneral'
import Inputs from 'components/form/inputs'
import Selects from 'components/form/selects'
import DatePicker from 'components/datepicker/DatePicker'

import moment from 'moment'
import dayjs from "dayjs";
import Textarea from 'components/form/textarea'
import { handleName, toPermalink } from 'utils/helpers'
import { handleGetDataJamaah } from 'stores/actions/booking'
import { Checkbox } from 'antd'
import Badge from 'components/badge'
import Avatar from 'components/avatar'

const Miningitiss = ({
    data,
    jamaahOrderID,
    onSuccess,
    permissions,
    jamaahName,
    orderNumber,
    auth
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const formik = useFormik({
        initialValues: {
            order_kamar_mapping_id: jamaahOrderID,
            data
        }
    })
    const [isLoading, setIsLoading] = useState(false)

    const [documentRecipient, setDocumentRecipient] = useState(null)
    const [documentRecipientSaved, setDocumentRecipientSaved] = useState(false)

    const { bookingDetail } = useSelector(state => state.booking)

    const handleSave = async () => {
        // console.log(formik.values);
        setIsLoading(true)
        const response = await handleUpdateManifestAPI(params.orderNumber, formik.values)
        setIsLoading(false)
        if(response.status === 200) {
            dispatch(handleSuccess(null, { message: "Berhasil mengupdate data manifest", code: 200 }))
            dispatch(handleGetDataJamaah(params.orderNumber))
            onSuccess()
        } else {
            dispatch(handleError(response.data))
        }
        setDocumentRecipientSaved(true)
        formik.setFieldValue('data.meningitis_vaccine_document_recipient', documentRecipient)
    }

    const handleCheckDocumentRecipient = (val) => {
        
        if(val) {
            formik.setFieldValue('data.meningitis_vaccine_document_recipient', auth?.user?.id)

            console.log("Halo", auth)

            const admin = {
                'id' : auth?.user?.id,
                'name' : auth?.user?.name,
                'title': auth?.user?.title,
                'photo_profile': auth?.user?.image_profile,
                'role_name': auth?.user?.nama_role,
                'role_bg_color': auth?.user?.color_bg_role,
                'role_text_color': auth?.user?.color_text_role
            }

            console.log("Admin", admin, auth?.user?.image_profile)

            setDocumentRecipient(admin)

        } else {
            formik.setFieldValue('data.meningitis_vaccine_document_recipient', null)
            setDocumentRecipient(null)
        }
    }
    
    useEffect(() => {
        formik.setValues({
            order_kamar_mapping_id: jamaahOrderID,
            data
        })

        if (data?.meningitis_vaccine_document_recipient !== null) {
            if (typeof data?.meningitis_vaccine_document_recipient === 'object' && !Array.isArray(data?.meningitis_vaccine_document_recipient)) {
                console.log("Halo", data?.meningitis_vaccine_document_recipient)
                formik.setFieldValue('data.meningitis_vaccine_document_recipient', data?.meningitis_vaccine_document_recipient?.id)
                setDocumentRecipient(data?.meningitis_vaccine_document_recipient)
                setDocumentRecipientSaved(true)
            } else {
                formik.setFieldValue('data.meningitis_vaccine_document_recipient', data?.meningitis_vaccine_document_recipient)
            }
        }
    }, [data])



    return (
        <>
            <div className="border p-4 rounded-t-2xl">
                <div 
                    className='rounded-2xl p-4 border border-gray-4 flex justify-between items-center bg-[#FBF3F2] mb-4'>
                    <div className='font-semibold'>
                        {documentRecipient ? (
                            <>
                                <div className='flex gap-3 items-center'>
                                    <div>
                                        <Avatar 
                                            containerClass=
                                            "items-center justify-center  w-[56px] h-[56px] rounded-[100%]  bg-slate-100"
                                            customClass="object-cover w-[56px] h-[56px] rounded-[100%]"
                                            value={documentRecipient}
                                            />
                                    </div>
                                    <div>
                                        <div className='mb-2 font-normal text-xs'>
                                            {handleName(documentRecipient?.title, documentRecipient?.name)}
                                        </div>
                                        <Badge 
                                            customClass='text-xs'
                                            label={documentRecipient?.role_name}
                                            backgroundColor={documentRecipient?.role_bg_color}
                                            color={documentRecipient?.role_text_color}
                                            />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                Dokumen Fisik Telah Diterima
                            </>
                        )}
                    </div>
                    <div>
                        <Checkbox 
                            checked={formik.values?.data?.meningitis_vaccine_document_recipient != null ? true : false}
                            onChange={(val) => {
                                handleCheckDocumentRecipient(val?.target?.checked)
                            }}
                            disabled={!permissions.includes('update_manifest') || documentRecipientSaved}
                            />
                    </div>
                </div>
                <div className='flex gap-4'>
                    <div className='w-full'>
                        <UploadArea
                            viewOnly={!permissions.includes('update_manifest') || bookingDetail?.payment_status === 4}
                            type='chose'
                            label="Dokumen Meningitis Vaksin"
                            customClass="w-full h-[350px]"
                            defaultUrl={formik.values?.data?.meningitis_vaccine}
                            onChange={(url) => formik.setFieldValue('data.meningitis_vaccine', url)}
                            handleRemove={() => formik.setFieldValue('data.meningitis_vaccine', null)}
                            customName={`meningitis_vaccine-${orderNumber}-${toPermalink(jamaahName)}`}
                            />
                    </div>
                </div>
            </div>
            {permissions.includes('update_manifest') && bookingDetail?.payment_status !== 4 && (
                <div className="border rounded-b-2xl flex justify-between border-t-0 p-4">
                    <div>
                    </div>
                    <div>
                        <Buttons
                            customClass="btn-primary btn-large"
                            text="Simpan"
                            loading={isLoading}
                            onClick={handleSave}
                            />
                    </div>
                </div>
            )}
        </>
    )
}

export default Miningitiss