import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Badge from "../../../../components/badge";
import MoslemeTable from "../../../../components/Table";

import { handleGetDataJamaah } from 'stores/actions/booking'
import Avatar from "components/avatar";
import { IDRFormater, toCapital, toCurrencyFormat } from "utils/helpers";

import IconEdit from "assets/icons/edit.svg";
import IconDelete from "assets/icons/trash.svg";
import { replace } from "formik";

const TabTagihan = ({
  handleUpdateAdditional,
  handleDeleteAdditional
}) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { bookingDataJamaah, bookingDetail } = useSelector((state) => state.booking);
  

  const handleRoomType = (roomType) => {
    roomType = roomType.replace('_bussiness', ' (Busines Class)')
    roomType = roomType.replace('tripple', 'triple')

    return roomType.toUpperCase()
  }

  useEffect(() => {
    dispatch(handleGetDataJamaah(params.orderNumber))
  }, [])

  const columns = [
    {
      title: "Nama",
      dataIndex: "additional_name",
    },
    {
      title: "Jumlah",
      dataIndex: "quantity",
    },
    {
      title: "Catatan",
      dataIndex: "note",
    },
    {
      title: "Harga",
      dataIndex: "price",
      render: (record) => <span>
        {toCurrencyFormat(record, bookingDetail?.currency, false)}
      </span>
    },
    {
      title: "Harga Total",
      render: (record) => (
        <>
          <span>
            {toCurrencyFormat(record.price * record.quantity, bookingDetail?.currency, false)}
          </span>
          {bookingDetail?.payment_status !== 4 && (
            <div className="action">
              <div className="action-content">
                <div className="action-content-item">
                  <img
                    className="cursor-pointer"
                    src={IconEdit}
                    alt="edit"
                    onClick={() => handleUpdateAdditional(record)}
                  />
                </div>
                {record.paket_additional.is_wajib !== '1' && (
                  <div className="action-content-item">
                    <img 
                      className="cursor-pointer" 
                      onClick={() => handleDeleteAdditional(record)} 
                      src={IconDelete} alt="delete" />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )
    }
  ]

  const CardTagihanJamaah = ({ jamaah }) => {
    return (
      <div className="card-tagihan-jamaah-wrapper">
        <div className="card-tagihan-jamaah-header flex flex-row items-center">
          <Avatar value={jamaah} />
          <div className="card-tagihan-jamaah-header-name">
            {toCapital(jamaah.jamaah_title)} {jamaah.jamaah_name.toUpperCase()}
          </div>
        </div>
        <div className="card-tagihan-jamaah-content">
          <div className="flex flex-row justify-between p-4">
            <div className="card-tagihan-jamaah-content-wrapper">
              <div className="card-tagihan-jamaah-content-wrapper-label">
                Tipe Kamar
              </div>
              <div className="card-tagihan-jamaah-content-wrapper-value">
                {handleRoomType(jamaah?.room_type)}
              </div>
            </div>
            <div className="card-tagihan-jamaah-content-wrapper">
              <div className="card-tagihan-jamaah-content-wrapper-label">
                Harga Paket
              </div>
              <div className="card-tagihan-jamaah-content-wrapper-value">
                {toCurrencyFormat(jamaah.kamar_price, bookingDetail?.currency, false)}
              </div>
            </div>
            <div>
              <Badge 
                label={jamaah.name_package_starting.toUpperCase()}
                backgroundColor={jamaah.package_starting.bg_color}
                color={jamaah.package_starting.text_color}
                />
            </div>
          </div>

          <div className="px-4 mt-1 mb-4">
            <div>Additional</div>
            <MoslemeTable
              // bordered
              columns={columns}
              data={jamaah.paket_additional}
              customClass="tbl-jamaah border mt-4 rounded-xl overflow-hidden"
              withSelection={false}
              selectionType=""
              withPagination={false}
              customPaginationClass="pr-4"
            />
          </div>
        </div>
        <div className="card-tagihan-jamaah-footer p-4">
          <div className="card-tagihan-jamaah-footer-wrapper">
            <div className="card-tagihan-jamaah-footer-wrapper-label">
              Total Tagihan:
            </div>
            <div className="card-tagihan-jamaah-footer-wrapper-name mt-2">
              {toCurrencyFormat(jamaah.total_price, bookingDetail?.currency, false)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-2 mt-4"> 
        {!!bookingDataJamaah.length && bookingDataJamaah.map((jamaah, index) => 
          (
            <>
              <CardTagihanJamaah 
                jamaah={jamaah}
                />
            </>
          )
        )}
      </div>
    </>
  );
};

export default TabTagihan;
