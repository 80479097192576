import Buttons from "components/buttons";
import InputsCurrency from "components/form/inputsCurrency";
import Switch from "components/form/switch";
import Segment from "components/segment";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleGetExchangeRate } from "stores/actions/exchangeRateUSD";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";
import { saveExchangeRateService } from "utils/api/exchangeRateAPI";

import * as Yup from 'yup';

const ExchangeRateUSDSetting = () => {
  const dispatch = useDispatch();
  const { exchangeRate } = useSelector(({ exchangeRateUSD }) => exchangeRateUSD);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      scraping_status: "off",
      scraping_url: '',
      rate_sell: 0,
      rate_buy: 0,
    },
    validationSchema: Yup.object().shape({
      scraping_status: Yup.string().required(),
      scraping_url: Yup.string().required(),
      rate_sell: Yup.number().required(),
      rate_buy: Yup.number().required(),
    })
  });

  const handleSaveSetting = async () => {
    setLoading(true)
    const payload = {
      ...formik.values
    }
    const response = await saveExchangeRateService(payload)
    setLoading(false)

    if(response.status === 200) {
      dispatch(handleSuccess(response))
      dispatch(handleGetExchangeRate())
    } else {
      dispatch(handleError(response.data))
    }

  }

  useEffect(() => {
    formik.setFieldValue('scraping_status', exchangeRate.scraping_status)
    formik.setFieldValue('scraping_url', exchangeRate.scraping_url)
    formik.setFieldValue('rate_sell', exchangeRate.rate_sell)
    formik.setFieldValue('rate_buy', exchangeRate.rate_buy)
  }, [exchangeRate])

  return (
    <>
        <div className="rounded-2xl border">
            <div className="p-4">
                <div className="flex items-center gap-2 mb-4">
                    <Switch 
                        defaultChecked={exchangeRate.scraping_status === 'on'} 
                        onChange={(value) => {
                        formik.setFieldValue('scraping_status', value ? 'on' : 'off')
                    }} />
                    <div className="text-sm">Perbarui Otomatis</div>
                </div>
                {formik.values.scraping_status === 'on' && (
                    <div className="mb-4">
                      <Segment
                          size="small"
                          // disabled={(auth.user.access_data !== 'all_branches') || ((payment.status_payment === '3' && payment.payment_method == 2) || payment.status_payment == 4 || !permissions?.includes('update_bill') || (bookingDetail?.payment_status === 4 && payment.type === 'PAYMENT'))}
                          value={formik.values.scraping_url}
                          onChange={(value) => {
                              formik.setFieldValue('scraping_url', value)
                          }}
                          options={[
                              {
                                  label: (
                                      <div>
                                          Mulia Money Changer
                                      </div>
                                  ),
                                  value: 'https://muliamoneychanger.co.id/',
                                  },
                                  {
                                  label: (
                                      <div>
                                          Barumun Abadi Raya
                                      </div>
                                  ),
                                  value: 'https://barumunabadiraya.co.id/web/',
                              }
                              ]}
                          />
                      </div>
                )}
                <div className="mb-4">
                    <InputsCurrency
                        disabled={true}
                        label="USD"
                        currency="USD"
                        value={1}
                    />
                </div>
                <div className="mb-4">
                    <InputsCurrency
                        disabled={formik.values.scraping_status === 'on'}
                        label="Harga Jual"
                        currency="IDR"
                        value={formik.values.rate_sell}
                        onChange={(value) => {
                            formik.setFieldValue('rate_sell', value)
                        }}
                    />
                </div>
                <div className="mb-4">
                    <InputsCurrency
                        disabled={formik.values.scraping_status === 'on'}
                        label="Harga Beli"
                        currency="IDR"
                        value={formik.values.rate_buy}
                        onChange={(value) => {
                            formik.setFieldValue('rate_buy', value)
                        }}
                    />
                </div>
            </div>
            <div className="p-4 border-t flex justify-end items-center gap-2">
                <Buttons 
                  customClass="btn-primary btn-large" 
                  disabled={!(formik.isValid && formik.dirty)}
                  text="Simpan" 
                  loading={loading}
                  onClick={handleSaveSetting}
                  />
            </div>
        </div>
    </>
  );
};

export default ExchangeRateUSDSetting;